import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { useLocation } from "react-router-dom";
import Modal from "rsuite/Modal"
import { Button, Form, Icon, Message, MessageHeader, MessageItem, MessageList, Popup} from "semantic-ui-react";
import { Reacttable } from "../component/Reacttable";
import Table from "react-bootstrap/Table"
import { curencyNoSym, currentDateTime, currentTime } from "../component/utils";
import Badge from "rsuite/Badge";
import { Toaster } from "../component/Toaster";

export const PosRetur = () => {
    const openPos = useLocation().state
    const { host, userProfile } = useMyGlobal()
    const [trxDetail, setTrxDetail] = useState([])
    const [modalItem, setModalItem] = useState(false)
    const [dataTrx, setDataTrx] = useState([])
    const [codeTrx, setCodeTrx] = useState('')
    const [selectedItem, setSelectedItem] = useState([])
    const [returnDesc, setReturnDesc] = useState('')
    const [loadRetur, setLoadRetur] = useState(false)
    const [newReturnCode, setNewReturnCode] = useState('')

    const columnsItem = useMemo(() => [
        {
            Header: 'Article',
            accessor: "article"
        },
        {
            Header: 'Description',
            accessor: "description"
        },
        {
            Header: 'Qty',
            accessor: "qty"
        },
        {
            Header: 'Size',
            accessor: "size"
        },
        {
            Header: 'Price',
            accessor: "price"
        },
        {
            Header: 'AdDsc',
            accessor: "add_discount",
            Cell:({cell}) => cell.value > 0 ? `${cell.value}%` : 0
        },
        {
            Header: 'Nett',
            accessor: "final_price"
        },
        {
            Header: 'Status',
            accessor: "is_return",
            Cell: ({cell}) => (
                cell.value === "1" ? <><Badge color="red" className="me-1"/> <span>Returned</span></> : ""
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell:({cell}) => (
                <Button.Group basic size="mini">
                    <Popup content="Select this item" size="tiny" trigger={
                        <Button icon="arrow right" onClick={() => handleSelectItem(cell.row.original)} disabled={cell.row.original.is_return === '1' ? true : false}/>
                    }/>
                </Button.Group>
            )
        },
    ])

    const handleSelectItem = (data) => {
        setSelectedItem(prev => {
            const exist = prev.some(item => item.article === data.article && item.size === data.size)
            if (!exist){
                Toaster.fire({
                    icon:'success',
                    text:'Data has been added to list',
                    position:'top-right'
                })
                return [...prev, data]
            } else {
                alert('Data sudah dipilih')
            }
            return prev
        })
        
        setModalItem(true)
    }
    
    const getTrxDetail = async (codeTrx) => {
        axios({
            method: "GET",
            url: `${host}/pos/index.php?action=getTrxDetailByCode&code_trx=${codeTrx}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }).then(res => {
            if (res.data.status === 200) {
                setTrxDetail(res.data.data)
            }
        })
    }

    const getReturnCode = async () => {
        await axios({
            method: "GET",
            url: `${host}/pos/index.php?action=getReturnNumber`,
            headers: {
                "Content-Type": "application/json",
            },
        }).then (res => {
            setNewReturnCode(res.data)
        })
    }

    const getTrxByCode = async (codeTrx) => {
        await axios({
            method: "GET",
            url: `${host}/pos/index.php?action=getTransactionByCode&code_trx=${codeTrx}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }).then(res => { console.log(res.data.data)
            if (res.data.status === 200) {
                setDataTrx(res.data.data)
                setModalItem(true)
            }
        })
    }

    const handleClickLookup = () => {
        if (codeTrx){
            getTrxByCode(codeTrx)
        } else {
            Toaster.fire({
                icon:'warning',
                text:'Transaction code cannot be empty',
                position:'top-right'
            })
        }
        // setModalItem(true)
    }

    const handlePrint = (data) => {
        const locHost = "http://localhost"
        axios({
            method: "POST",
            url: `${locHost}/pos_print/`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data)
        }).then(res => {
            if (res.data.status === 'success') {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success transaction',
                    position: 'top-right'
                })
            }
        })
    }
    
    const handleSubmitRetur = async () => {
        const msg = window.confirm("The selected items will be returned to stock and stock will increase, are you sure?")
        if (selectedItem.length > 0) {
            if (msg) {
                setLoadRetur(true)
                const header = {
                    id_open:openPos.id_open,
                    code_trx:codeTrx,
                    date: currentDateTime,
                    id_user: openPos.id_user,
                    user_name: openPos.user_name,
                    id_warehouse:openPos.id_warehouse,
                    id_showroom:openPos.id_showroom,
                    showroom_name:openPos.showroom_name,
                    showroom_address:openPos.address,
                    description:returnDesc,
                    return_code : newReturnCode,
                    total : selectedItem.reduce((total, item) => total + parseFloat(item.final_price),0)
                }
                const detail = selectedItem

                const returnPrintData = {
                    header : header,
                    detail : detail,
                    action : "Return",
                }
               
                await axios({
                    method: "POST",
                    url: `${host}/pos/index.php?action=returnToStock`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data:{header, detail}
                }).then(res => {
                    if (res.data.status === 200){
                        Toaster.fire({
                            icon:'success',
                            text:'Retur success',
                            position:'top-right'
                        })
                        handlePrint(returnPrintData)
                        setSelectedItem([])
                        setReturnDesc('')
                        setCodeTrx('')
                    } else {
                        Toaster.fire({
                            icon:'error',
                            text:res.data.msg,
                            position:'top-right'
                        })
                    }
                    setLoadRetur(false)
                })
            }
        } else {
            alert("No item selected...!")
        }
    }

    const handleRemoveItem = (item) => {
        const newSelected = selectedItem?.filter(i => !(i.article === item.article && i.size === item.size))
        setSelectedItem(newSelected)
    }

    useEffect(() => {
        getReturnCode()
    },[])
 
    return (
        <div className="d-flex flex-column min-vh-100">
            <Container >
                <div className="mt-2 fs-5 fw-bold">Transaction Return</div>
                <Row className="mt-3 mb-3 d-flex justify-content-center">
                    <Col xl={8} lg={10} md={12} className="mb-3">
                    <div className="mb-3">
                        <span className="me-2"><Icon name="retweet" color="orange"/></span>
                        <span className="fw-bold fs-6">Return of stock</span>
                    </div>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    action={{ content: 'Lookup',color:'blue', onClick: handleClickLookup }}
                                    placeholder="Code Trx"
                                    label="Transaction Code"
                                    value={codeTrx}
                                    onChange={(e) => setCodeTrx(e.target.value)}
                                />
                                <Form.Input
                                    width={8}
                                    label="Description"
                                    value={returnDesc}
                                    onChange={(e) => setReturnDesc(e.target.value)}
                                    placeholder="Return Description"
                                />
                            </Form.Group>
                            
                        </Form>
                    </Col>
                    <Col xl={8} lg={10} md={12}>
                    <div style={{border:'1px solid lightgrey', borderRadius:'5px', padding:'10px'}}>
                        <div className="fw-bold">Selected Return Item</div>
                        <Table>
                            <thead style={{fontSize:'0.9em'}}>
                                <tr >
                                    <th>#</th>
                                    <th>Article</th>
                                    <th>Description</th>
                                    <th>Size</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Nett</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{fontSize:'0.8em'}}>
                                {
                                    selectedItem.length > 0 ?
                                    selectedItem?.map((item, i) => (
                                        <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>{item.article}</td>
                                            <td>{item.description}</td>
                                            <td>{item.size}</td>
                                            <td>{item.qty}</td>
                                            <td>{item.price}</td>
                                            <td>{item.final_price}</td>
                                            <td>
                                                <Icon name="close" onClick={() => handleRemoveItem(item)} style={{cursor:'pointer'}}/>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={8} className="text-center">No selected item...!</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        <div className="text-end">
                            <Button content="Submit Return" color="blue" onClick={handleSubmitRetur} disabled={selectedItem.length > 0 ? false : true} loading={loadRetur}/>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-auto mb-5">
                <Row className="d-flex justify-content-center">
                    <Col xl={8} lg={10} md={12}>
                        <div className="fw-bold">Note :</div>  
                        <ul>
                            <li>Return the goods first, so that the goods are back in stock, you can do it on this page</li>
                            <li>Once successful, you must immediately carry out a transaction at the POS with the replacement item</li>
                            <li>The return process is complete.</li>
                        </ul> 
                    </Col>
                </Row>
            </Container>

            <Modal open={modalItem} onClose={() => setModalItem(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>List Item of Selected Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={dataTrx?.detail} columns={columnsItem} pageRow={10}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}