import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Form, Popup } from "semantic-ui-react"
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import Modal from "rsuite/Modal"
import moment from "moment";
import DateRangePicker from 'rsuite/DateRangePicker';
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";
import Toggle from "rsuite/Toggle"
import Badge from "rsuite/Badge"
import { curencyNoSym } from "../component/utils";

export const Voucher = () => {
    const {host, userProfile, showroomOpt} = useMyGlobal()
    const [voucherData, setVoucherData] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [showroom, setShowroom] = useState('')
    const [voucherDesc, setVoucherDesc] = useState('')
    const [voucherCode, setVoucherCode] = useState('')
    const [qty, setQty] = useState(0)
    const [nominal, setNominal] = useState(0)
    const [selectedRow, setSelectedRow] = useState(null)
    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])
    const [dateRangeEdit, setDateRangeEdit] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columns = useMemo (() => [
        {
            Header: 'ID',
            accessor: "id_voucher"
        },
        {
            Header: 'Showroom',
            accessor: "showroom_name"
        },
        {
            Header: 'Description',
            accessor: "desc"
        },
        {
            Header: 'Active Date',
            accessor: "start_date"
        },
        {
            Header: 'End Date',
            accessor: "end_date"
        },
        {
            Header: 'Code',
            accessor: "code"
        },
        {
            Header: 'Nominal',
            accessor: "nominal",
            Cell:({cell}) => (<div>{curencyNoSym.format(cell.value)}</div>)
        },
        {
            Header: 'Qty',
            accessor: "qty"
        },
        {
            Header: 'Used',
            accessor: "used"
        },
        {
            Header: 'Status',
            accessor: "status",
            Cell:({cell}) => (
                <>
                <Badge className="me-1" color={cell.value === 'running' ? 'green' : cell.value === 'expired' ? 'orange' : cell.value === 'upcoming' ? 'blue' : ''}/><span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
        {
            Header: 'Enable',
            accessor: "is_active",
            Cell: ({ cell }) => (
                <Toggle size="sm" defaultChecked={cell.value === 'Y' ? true : false} onChange={() => handleChangeStatus(cell.value, cell.row.original.id_voucher)} />
            )
        },
        {
            Header: 'Create By',
            accessor: "user_name",
            Cell: ({cell}) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div style={{lineHeight:'0.6em'}} className="text-muted">{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell:({cell}) => (
                <Button.Group basic size="mini">
                    <Popup content="Edit" size="tiny" trigger={
                        <Button icon={{name:'edit', color:'blue'}} onClick={() => handleClickEdit(cell.row.original)}/>
                    }/>
                </Button.Group>
            )
        },
    ])

    const handleClickEdit = (data) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        setSelectedRow(data)
        setShowroom(data.id_showroom)
        setVoucherDesc(data.desc)
        setVoucherCode(data.code)
        setQty(data.qty)
        setNominal(data.nominal)
        setDateRange([
            new Date(data.start_date),
            new Date(data.end_date),
        ])
        setModalEdit(true)
    }

    const handleSubmitEdit = () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        const param = {
            id_voucher:selectedRow?.id_voucher,
            showroom:showroom,
            desc:voucherDesc,
            code:voucherCode,
            start:date_range[0],
            end:date_range[1],
            qty:qty,
            nominal:nominal,
        }

        axios({
            method: "PUT",
            url: `${host}/sales/index.php?action=editVoucher`,
            headers: {
                "Content-Type": "application/json",
            },
            data: param,
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success update voucher',
                    position:'top-right'
                })
                getVoucher()
                setModalEdit(false)
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const handleChangeStatus = (value, idVoucher) => {
        const status = value === 'Y' ? 'N' : 'Y'
        axios({
            method: "PUT",
            url: `${host}/sales/index.php?action=changeStatus`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { id_voucher: idVoucher, status: status },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success change status',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleChangeShowroom = (e, {value}) => {
        setShowroom(value)
    }

    const handleClickCreate = () => {
        setModalAdd(true)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleSubmitCreate = () => {
        const param = new FormData()
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        param.append('showroom', showroom)
        param.append('desc', voucherDesc)
        param.append('start', date_range[0])
        param.append('end', date_range[1])
        param.append('id_user', userProfile.profile.id_user)
        param.append('code', voucherCode)
        param.append('qty', qty)
        param.append('nominal', nominal)

        axios({
            method: "POST",
            url: `${host}/sales/index.php?action=CreateVoucher`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data:param
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success create voucher',
                    position:'top-right',
                })
                getVoucher()
                setModalAdd(false)
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right',
                })
            }
        })
    }

    const getVoucher = () => {
        axios({
            method: "GET",
            url: `${host}/sales/index.php?action=voucher`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then (res => {
            if (res.data.status === 200){
                setVoucherData(res.data.data)
            }
        })
    }

    useEffect(() => {
        getVoucher()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Voucher</div>
                </div>
                <Row className="d-flex justify-content-start">
                    <Col lg={12} className="mb-3">
                        <Button content="Create New Voucher" icon="add" onClick={handleClickCreate} color="blue"/>
                    </Col>
                    <Col lg={12}>
                        <Reacttable data={voucherData} columns={columns} pageRow={10} noData={voucherData.length === 0 ? true : false}/>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Create New Voucher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmitCreate}>
                            <Form.Dropdown
                                label="Showroom"
                                selection
                                clearable
                                search
                                options={showroomOpt}
                                value={showroom}
                                onChange={handleChangeShowroom}
                                required
                            />
                            <Form.Input type="text"  value={voucherDesc} onChange={(e) => setVoucherDesc(e.target.value)} label="Voucher Description" required/>
                            <Form.Group>
                                <Form.Input type="text" width={5} maxLength={10} value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} label="Voucher Code" required/>
                                <Form.Field>
                                    <label>Date Range</label>
                                    <DateRangePicker
                                        appearance="subtle"
                                        format="yyyy-MM-dd"
                                        placement="bottomStart"
                                        cleanable={true}
                                        defaultValue={dateRange}
                                        value={dateRange}
                                        onOk={handleChangeDate}
                                        onClean={() => setDateRange([
                                            new Date(moment().startOf('month')),
                                            new Date(moment()),
                                        ])}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input width={5} type="number" value={qty} onChange={(e) => setQty(e.target.value)} label="Quantity" required/>
                                <Form.Input width={12}type="number" value={nominal} onChange={(e) => setNominal(e.target.value)} label="Nominal" required/>
                            </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create Voucher" type="submit" form="frmAdd" icon="plus" color="blue"/>
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Voucher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                            <Form.Dropdown
                                label="Showroom"
                                selection
                                clearable
                                search
                                options={showroomOpt}
                                value={showroom}
                                onChange={handleChangeShowroom}
                                required
                            />
                            <Form.Input type="text"  value={voucherDesc} onChange={(e) => setVoucherDesc(e.target.value)} label="Voucher Description" required/>
                            <Form.Group>
                                <Form.Input type="text" width={5} maxLength={10} value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} label="Voucher Code" required/>
                                <Form.Field>
                                    <label>Date Range</label>
                                    <DateRangePicker
                                        appearance="subtle"
                                        format="yyyy-MM-dd"
                                        placement="bottomStart"
                                        cleanable={true}
                                        defaultValue={dateRange}
                                        value={dateRange}
                                        onOk={handleChangeDate}
                                        onClean={() => setDateRange([
                                            new Date(moment().startOf('month')),
                                            new Date(moment()),
                                        ])}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input width={5} type="number" value={qty} onChange={(e) => setQty(e.target.value)} label="Quantity" required/>
                                <Form.Input width={12} type="number" value={nominal} onChange={(e) => setNominal(e.target.value)} label="Nominal" required/>
                            </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update Voucher" type="submit" form="frmEdit" icon="save" color="blue"/>
                </Modal.Footer>
            </Modal>

        </>
    )
}