import React, { useRef, useImperativeHandle, forwardRef, useMemo } from "react";
import { useTable, usePagination, useExpanded, useRowSelect, useGlobalFilter, useSortBy } from "react-table";
import Tbl from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Button, Dropdown, Input, Icon, Label, Loader } from "semantic-ui-react";
import { useState, useEffect } from "react";
import emptyImg from "../asset/no_data.png";
import { curencyNoSym, tableBodyStyle } from "./utils";

export const Reacttable = forwardRef(({ columns: userColumns, data, renderRowSubComponent, checkbox, pageRow, hideColumn, bordered = false, totals = [], noData = false}, ref) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        selectedFlatRows,
        visibleColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    } = useTable(
        {
            columns: userColumns,
            data,
            autoResetPage: false,
            autoResetFilters: false,
            initialState: { pageIndex: 0, pageSize: pageRow ? pageRow : 10, hiddenColumns: hideColumn ? hideColumn : '' },
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if (checkbox) {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox className="align-middle" {...getToggleAllPageRowsSelectedProps()} />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox className="align-middle" {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }
    );

    const [loading, setLoading] = useState(false);
    const [stopLoad, setStopLoad] = useState(false);

    const opt = [
        { key: '5', value: 5, text: '5 records' },
        { key: '10', value: 10, text: '10 records' },
        { key: '20', value: 20, text: '20 records' },
        { key: '50', value: 50, text: '50 records' },
        { key: '100', value: 100, text: '100 records' },
    ];

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            );
        }
    );

    useImperativeHandle(ref, () => ({
        getSelectedRows: () => selectedFlatRows.map(row => row.original)
    }));

    const handleChangePage = (e, { value }) => {
        setPageSize(value);
    };

    useEffect(() => {
        if (data.length === 0) {
            setLoading(false);
        } else if (data.length > 0) {
            setLoading(true);
        }
    }, [data]);

    useEffect(() => { 
        if (data.length > 0){
            setStopLoad(true)
        }
        if (data.length === 0){
            setStopLoad(true)
        }
    }, [data]);

    const totalValues = useMemo(() => {
            const totalsObj = {};
            totals.forEach(columnId => {
                totalsObj[columnId] = rows.reduce((sum, row) => row.values[columnId] ? sum + parseFloat(row.values[columnId]) : sum, 0);
            });
            return totalsObj;

    }, [rows, totals]);
     
    return (
        <>
            <Row className="mb-3">
                <Col xl={6} slg={6} md={6} xs={6} className="d-flex justify-content-start mb-2">
                    <Label  basic content={data ? data.length + ' ' + 'Rows' : 0} icon="list"/>
                </Col>
                <Col xl={6} slg={6} md={6} xs={6} className="d-flex justify-content-end mb-2">
                    <Input value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} icon="search" size="mini" placeholder="Search" />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <Tbl hover bordered={bordered} responsive {...getTableProps()} style={{ fontSize: '0.9em' }}>
                        <thead style={{ whiteSpace: 'nowrap' }}>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            onClick={() => {
                                                column.canSort && column.toggleSortBy(!column.isSortedDesc);
                                            }}
                                            className={column.className ? '' : column.className}
                                            style={{
                                                textAlign: column.className === 'parent-header' ? 'center' : 'left',
                                            }}
                                        >
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " 🔽"
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()} style={tableBodyStyle}>
                            {
                                !stopLoad ?
                                    <tr hidden={loading}><td colSpan={userColumns.length} className="text-center"><Icon loading name='spinner' />Loading...</td></tr>
                                    :
                                    <tr hidden={loading}><td colSpan={userColumns.length} className="text-center"><Image src={emptyImg} width={120} height={120} /></td></tr>
                            }
                            
                            {page.map((row, i) => {
                                prepareRow(row);
                                const rowProps = row.getRowProps();
                                return (
                                    <React.Fragment key={rowProps.key}>
                                        <tr className="align-middle">
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr>
                                                <td colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                        {totals.length > 0 && (
                            <tfoot className="fw-bold">
                                <tr>
                                    <td>Total : </td>
                                    {headerGroups[0].headers.slice(1).map(column => (
                                        <td key={column.id}>
                                            {totals.includes(column.id) ? curencyNoSym.format(totalValues[column.id]) : ''}
                                        </td>
                                    ))}
                                </tr>
                            </tfoot>
                        )}
                    </Tbl>
                </Col>
                <Col lg={12} style={{ fontSize: '0.9em' }}>
                    <Row className="mt-3">
                        <Col lg={9} md={8} sm={12} xs={12} className="mb-2">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            | Show &nbsp;
                            <Dropdown
                                className="fw-bold"
                                options={opt}
                                onChange={handleChangePage}
                                defaultValue={pageSize}
                            /> &nbsp;
                            <span>
                                | Go to page:{' '}
                                <Input
                                    min={1}
                                    size="small"
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(page);
                                    }}
                                    style={{ width: '60px' }}
                                />
                            </span>{' '}
                        </Col>
                        <Col lg={3} md={4} sm={12} xs={12} className="d-flex justify-content-end mb-2">
                            <Button.Group floated="left" size="mini" basic>
                                <Button icon="angle double left" onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                                <Button icon="angle left" onClick={() => previousPage()} disabled={!canPreviousPage} />
                                <Button icon="angle right" onClick={() => nextPage()} disabled={!canNextPage} />
                                <Button icon="angle double right" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                            </Button.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
});
