import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";
import { Button, Dropdown } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import Stack from "react-bootstrap/Stack";
import { Toaster } from "../component/Toaster";
import axios from "axios";
import { curency, curencyNoSym } from "../component/utils";
import Card from "react-bootstrap/Card"

export const Dashboard = () => {
    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])
    const { host, showroomOpt } = useMyGlobal()
    const [salesData, setSalesData] = useState([])
    const [showroom, setShowroom] = useState('')
    const [chartSalesOpt, setChartSalesOpt] = useState({})
    const [bestSeler, setBestSeler] = useState([])
    const [salesPerMonth, setSalesPerMonth] = useState([])
    const [salesPerBrand, setSalesPerBrand] = useState([])

    const monthNames = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const getSalesData = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'posTransactionByShowroom',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesData(res.data.data)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const bestSelerItem = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'bestSellerItem',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setBestSeler(res.data.data)
            }
        })
    }

    const salesPerMonthData = async () => {

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'salesPerMonth',
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerMonth(res.data.data)
            }
        })
    }

    const getSalesPerBrand = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'salesPerBrand',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerBrand(res.data.data)
            }
        })
    }

    const handleSelectShowroom = (value) => {
        setShowroom(value)

    }

    const handleGenerate = () => {
        getSalesData()
        bestSelerItem()
        salesPerMonthData()
        getSalesPerBrand()
    }

    return (
        <Container>
            <div className="mb-3 mt-2">
                <div className="h4">Dashboard</div>
            </div>
            <Row className="d-flex justify-content-left mb-4">
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <Stack direction="horizontal" gap={3}>
                        <Dropdown
                            placeholder="Select Showroom"
                            selection
                            options={showroomOpt}
                            clearable
                            onChange={(e, { value }) => handleSelectShowroom(value)}
                        />
                        <DateRangePicker
                            disabled={showroom !== '' ? false : true}
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="bottomStart"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Generate Data" color="blue" icon="database" onClick={handleGenerate} disabled={showroom !== '' ? false : true} />
                    </Stack>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <Card>
                        <Card.Header className="text-capitalize">Sales data per date</Card.Header>
                        <Card.Body>
                            {
                                salesData ?
                                    <ReactApexChart
                                        type="bar"
                                        options={
                                            {
                                                plotOptions: {
                                                    bar: {
                                                        borderRadius: 0,
                                                        horizontal: false,
                                                    },
                                                },
                                                xaxis: {
                                                    categories: salesData
                                                        .map(sale => sale.header.trx_date)
                                                        .reduce((acc, date) => {
                                                            if (!acc.includes(date)) {
                                                                acc.push(date);
                                                            }
                                                            return acc;
                                                        }, [])
                                                        .sort((a, b) => new Date(a) - new Date(b))
                                                        .map(date => new Date(date).toLocaleDateString('in-US', {
                                                            weekday: 'long',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        }))
                                                },
                                                yaxis: {
                                                    labels: {
                                                        formatter: function (val) {
                                                            return curency.format(val);
                                                        },
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                    formatter: function (val) {
                                                        return curency.format(val);
                                                    },
                                                },
                                            }
                                        }
                                        series={[{
                                            name: 'Amount',
                                            data: Object.entries(salesData.reduce((acc, sale) => {
                                                const { trx_date, total_trx } = sale.header;
                                                if (!acc[trx_date]) {
                                                    acc[trx_date] = 0;
                                                }
                                                acc[trx_date] += parseFloat(total_trx);
                                                return acc;
                                            }, {}))
                                                .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB)) // Urutkan berdasarkan trx_date
                                                .map(([date, totalTrx]) => totalTrx) // Ambil total_payment saja

                                        }]}
                                        height={350}
                                    />
                                    : <>Loading</>
                            }
                        </Card.Body>
                        <Card.Footer>
                            <div><span className="text-muted">Total Sales</span> : <span>{curency.format(salesData.reduce((total, cur) => total + parseFloat(cur.header.total_trx), 0))}</span></div>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <Card>
                        <Card.Header className="text-capitalize">Sales data per Month</Card.Header>
                        <Card.Body>
                            {
                                salesPerMonth ?
                                    <ReactApexChart
                                        type="bar"
                                        options={
                                            {
                                                plotOptions: {
                                                    bar: {
                                                        borderRadius: 0,
                                                        horizontal: false,
                                                    },
                                                },
                                                xaxis: {
                                                    categories: salesPerMonth.map(item => { return `${monthNames[item.month - 1]} ${item.year}` })
                                                },
                                                yaxis: {
                                                    labels: {
                                                        formatter: function (val) {
                                                            return curency.format(val);
                                                        },
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: true,
                                                    textAnchor:"middle",
                                                    offsetY: -10,
                                                    formatter: function (val) {
                                                        return curency.format(val);
                                                    },
                                                },
                                            }
                                        }
                                        series={[{
                                            name: 'Amount',
                                            data: salesPerMonth.map(item => item.amount)
                                        }]}
                                        height={350}
                                    />
                                    : <>Loading</>
                            }
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <Card>
                        <Card.Header className="text-capitalize">Top 10 Best seller products</Card.Header>
                        <Card.Body>
                            {
                                bestSeler ?
                                    <ReactApexChart
                                        type="bar"
                                        options={
                                            {
                                                plotOptions: {
                                                    bar: {
                                                        borderRadius: 0,
                                                        horizontal: false,
                                                    },
                                                },
                                                xaxis: {
                                                    categories: bestSeler.map(item => item.article)
                                                },
                                                yaxis: {
                                                    labels: {
                                                        formatter: function (val) {
                                                            return val + ' Items';
                                                        },
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: true,
                                                },
                                                tooltip: {
                                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                        const qty = series[seriesIndex][dataPointIndex]
                                                        const desc = bestSeler[dataPointIndex].description
                                                        const price = curency.format(bestSeler[dataPointIndex].price)
                                                        return `<div style="padding: 10px; font-size:0.9em">
                                                                    <span>${desc}</span><br/>
                                                                    <span>Price : ${price}</span><br/>
                                                                    <span style="font-weight:bold">Sold : ${qty} </span><br/>
                                                                   
                                                                </div>`
                                                    }
                                                }
                                            }
                                        }
                                        series={[{
                                            data: bestSeler.map(item => item.sale)
                                        }]}
                                        height={350}
                                    />
                                    : <>Loading</>
                            }
                        </Card.Body>
                    </Card>

                </Col>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <Card>
                        <Card.Header className="text-capitalize">Sales Per Brand</Card.Header>
                        <Card.Body>
                            {
                                salesPerBrand ?
                                    <ReactApexChart
                                        type="donut"
                                        options={
                                            {
                                                chart: {
                                                    redrawOnParentResize: true,
                                                    redrawOnWindowResize: true
                                                },
                                                labels: salesPerBrand.map(item => item.description),
                                                dataLabels: {
                                                    enabled: true,
                                                },
                                                responsive: [{
                                                    breakpoint: 480,
                                                    options: {
                                                        chart: {
                                                            width: '100%',
                                                        },
                                                        legend: {
                                                            show: true,
                                                        }
                                                    }
                                                }],
                                                legend: {
                                                    position: 'right',
                                                    offsetY: 0,
                                                    height: '100%',
                                                    width: 150
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        startAngle: 0,
                                                        endAngle: 360,
                                                        expandOnClick: true,
                                                        offsetX: 0,
                                                        offsetY: 0,
                                                        customScale: 1,
                                                        dataLabels: {
                                                            offset: 0,
                                                            minAngleToShowLabel: 10
                                                        },
                                                        donut: {
                                                            size: "50%",
                                                        }
                                                    }
                                                },
                                                tooltip: {
                                                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                        const amount = curency.format(salesPerBrand[seriesIndex].total_final_price)
                                                        const qty = salesPerBrand[seriesIndex].total_qty
                                                        const brand = salesPerBrand[seriesIndex].description
                                                        return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Brand : ${brand} </span><br/>
                                                                                <span>Amount : ${amount}</span><br/>
                                                                                <span>Qty : ${qty} Items</span>
                                                                            </div>`
                                                    }
                                                },
                                            }
                                        }
                                        series={salesPerBrand.map(item => parseInt(item.total_final_price))}
                                        height={250}
                                    />
                                    : <>Loading</>
                            }
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Container>
    )
}