import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { Reacttable } from "../component/Reacttable";
import { useMyGlobal } from "../component/Global";
import { Button, Form, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import { Toaster } from "../component/Toaster";
import Stack from "react-bootstrap/Stack";

export const Brand = () => {
    const [dataBrand, setDataBrand] = useState([])
    const {host, userProfile} = useMyGlobal()
    const [code, setCode] = useState('')
    const [desc, setDesc] = useState('')
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_brand_code"
        },
        {
            Header: "Code",
            accessor: "brand_code"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Created At",
            accessor: "createAt"
        },
        {
            Header: "Last Update",
            accessor: "updateAt"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell:({cell}) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{lineHeight:'0.8em'}}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell : ({cell}) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{name:'edit', color:'blue'}} onClick={() => handleClickEdit(cell.row.original)}/>
                    }/>
                    <Popup size="tiny" content="Delete" trigger={
                        <Button icon={{name:'trash alternate', color:'blue'}} onClick={() => handleDeleteBrand(cell.row.original.id_brand_code)}/>
                    }/>
                </Button.Group>
            )
        },
    ])
    

    const handleClickEdit = (data) => { 
        setSelectedRow(data)
        setCode(data.brand_code)
        setDesc(data.description)
        setModalEdit(true)
    }

    const handleSubmitEdit = () => {
        const param = {
            code:code,
            desc:desc,
            id_brand:selectedRow.id_brand_code,
            id_user:userProfile.profile.id_user,
        }

        axios({
            method: "PUT",
            url: `${host}/product/index.php?action=editBrand`,
            headers: {
                "Content-Type": "application/json",
            },
            data:param
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success Update brand code',
                    position:'top-right'
                })
                getBrand()
                clearForm()
                setModalEdit(false)
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const getBrand = () => {
        axios({
            method: "GET",
            url: `${host}/product/index.php?action=brand`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then (res => {
            if (res.data.status === 200){
                setDataBrand(res.data.data)
            }
        })
    }

    const clearForm = () => {
        setCode('')
        setDesc('')
    }

    const handleDeleteBrand = (id) => {
        const param = {
            id_brand:id
        }
        const cfm = window.confirm('Are you sure want to delete this?')

        if (cfm){
            axios({
                method: "DELETE",
                url: `${host}/product/index.php?action=deleteBrand`,
                headers: {
                    "Content-Type": "application/json",
                },
                data : param
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:'Success delete brand code',
                        position:'top-right'
                    })
                    getBrand()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:'top-right'
                    })
                }
            })
        }
    }

    const handleSubmitBrand = () => {
        const param = new FormData()

        param.append('code', code)
        param.append('desc', desc)
        param.append('id_user', userProfile.profile.id_user)

        axios({
            method: "POST",
            url: `${host}/product/index.php?action=createBrand`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data:param
        }).then(res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success adding new brand code',
                    position:'top-right'
                })
                getBrand()
                setModalAdd(false)
                clearForm()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    useEffect(() => {
        getBrand()
    },[])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Brand</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={9} lg={12} md={12} className="mb-3">
                        <Button content="Add New Brand" icon="add" onClick={handleClickAdd} color="blue"/>
                    </Col>
                    <Col xl={9} lg={12} md={12}>
                        <Reacttable data={dataBrand} columns={columns} pageRow={10} noData={dataBrand.length === 0 ? true : false}/>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} onClose={() => setModalAdd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New Brand Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAdd" onSubmit={handleSubmitBrand}>
                        <Form.Input type="text" maxLength={3} value={code} onChange={(e) => setCode(e.target.value)} label="Brand Code" placeholder="NE" required/>
                        <Form.Input type="text" maxLength={35} value={desc} onChange={(e) => setDesc(e.target.value)} label="Description" placeholder="Nike" required/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create" icon="plus" type="submit" form="frmAdd"/>
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Brand Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Input type="text" maxLength={3} value={code} onChange={(e) => setCode(e.target.value)} label="Brand Code" placeholder="NE" required/>
                        <Form.Input type="text" maxLength={35} value={desc} onChange={(e) => setDesc(e.target.value)} label="Description" placeholder="Nike" required/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update" icon="edit" type="submit" form="frmEdit" color="blue"/>
                </Modal.Footer>
            </Modal>
        </>
    )
}