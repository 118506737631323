import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { capitalize } from './utils';

const MyContext = createContext();

export const GlobalData = ({ children }) => {
    const [cityOpt, setCityOpt] = useState(null)
    const [provOpt, setProvOpt] = useState(null)
    const [warehouseOpt, setWarehouseOpt] = useState([])
    const [showroomOpt, setShowroomOpt] = useState([])
    const [supplierOpt, setSupplierOpt] = useState([])
    const userProfile = Cookies.get('_erpLog') ? JSON.parse(Cookies.get('_erpLog')) : null

    const getCityAndProv = async () => {
        await axios({
            method: 'GET',
            url: `${host}/purchasing/index.php?action=cityAndProv`,
        }).then(res => {
            if (res.data.status === 200) {
                setCityOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: item.id,
                            text: item.city,
                            value: item.id,
                        }
                    ))
                )

                setProvOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: item.id,
                            text: item.province,
                            value: item.id,
                        }
                    ))
                )
            }
        })
    }

    const getVendorData = async () => {
        await axios({
            method: "GET",
            url: `${host}/purchasing/index.php?action=vendorMaster`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSupplierOpt(res.data.data.map(i => {
                    return { key: i.id_vendor, text: i.name, value: i.id_vendor }
                }))
            }
        })
    }

    const getWarehouseData = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=warehouse`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                setWarehouseOpt(res.data.data.map(i => {
                    return { key: i.id_warehouse, text: capitalize(i.desc), value: i.id_warehouse }
                }))
            }
        })
    }

    const getShowroomData = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=showroom`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                setShowroomOpt(res.data.data.map(i => {
                    return { key: i.id_showroom, text: capitalize(i.showroom_name), value: i.id_showroom }
                }))
            }
        })
    }


    const IDR = new Intl.NumberFormat('IN', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        currencyDisplay: 'symbol'
    });

    const host = "https://erp.hoopsdevteam.com/api_erp_prod"
    // const host = "http://localhost/api_erp/"

    useEffect(() => {
        getCityAndProv()
        getWarehouseData()
        getShowroomData()
        getVendorData()
    }, [])

    return (
        <MyContext.Provider value={{
            IDR,
            host,
            cityOpt,
            provOpt,
            userProfile,
            warehouseOpt,
            showroomOpt,
            supplierOpt,
        }}>
            {children}
        </MyContext.Provider>
    )
}

export const useMyGlobal = () => {
    return useContext(MyContext);
};