import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import moment from "moment";
import { Button, Dropdown, Form, Icon } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import { Toaster } from "../component/Toaster";
import { curency } from "../component/utils";


export const LockerRent = () => {
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [modalAddLocker, setModalAddLocker] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [showroom, setShowroom] = useState('')
    const [lockNumber, setLockNumber] = useState('')
    const [desc, setDesc] = useState('')
    const [lockerData, setLockerData] = useState([])
    const [showroomLockerData, setShowroomLockerData] = useState('')
    const [selectedLocker, setSelectedLocker] = useState(null)

    const handleClickAddLocker = () => {
        setModalAddLocker(true)
    }

    const getLocker = async () => {
        await axios({
            method: 'GET',
            url: `${host}/loyalty/?action=lockerByShowroom`,
            headers: {
                "Content-Type": "application/json"
            },
            params: {
                id_showroom: showroomLockerData
            }
        }).then(res => {
            if (res.data.status === 200) {
                setLockerData(res.data.data)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleSubmitAddLocker = async () => {
        if (showroom === '' || lockNumber === '' || desc === '') {
            Toaster.fire({
                icon:'warning',
                text:'Please complete all input field',
                position:'top-right'
            })
        } else {
            const param = {
                id_showroom: showroom,
                id_user: userProfile.profile.id_user,
                locker_number: lockNumber,
                description: desc
            }
            await axios({
                method: 'POST',
                url: `${host}/loyalty/?action=addLocker`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: "Success add new Locker",
                        position: 'top-right'
                    })
                    setModalAddLocker(false)
                    getLocker()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const handleClickEdit = (data) => {
        setSelectedLocker(data)
        setLockNumber(data.locker_number)
        setDesc(data.dscription)
        setShowroom(data.id_showroom)
        setModalEdit(true)
    }

    const handleSubmitDelete = (id) => {
        const cfm = window.confirm('Are you sure...?')
        if (cfm){
            axios({
                method: 'DELETE',
                url: `${host}/loyalty/?action=deleteLocker`,
                headers: {
                    "Content-Type": "application/json"
                },
                params:{
                    id:id
                }
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:'Success delete locker',
                        position:'top-right'
                    })
                    getLocker()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:'top-right'
                    })
                }
            })
        }
    }

    const handleSubmitEdit = () => {
        if (selectedLocker){
            const param = {
                id : selectedLocker.id,
                id_showroom:selectedLocker.id_showroom,
                description:desc,
                locker_number:lockNumber,
            }
       
            axios({
                method: 'PUT',
                url: `${host}/loyalty/?action=editLocker`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: param
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:'Success edit locker',
                        position:'top-right'
                    })
                    setModalEdit(false)
                    getLocker()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:'top-right'
                    })
                }
                    
            })

        } else {
            alert('Parameter not complete')
        }
    }

    return (
        <>
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} className="mt-2 mb-3">
                        <div className="h4">Available Locker</div>
                    </Col>
                    <Col xl={10} lg={12} className="mb-3">
                        <label className="me-3">Select Showroom</label>
                        <Dropdown
                            className="me-3"
                            placeholder="Select Showroom"
                            selection
                            options={showroomOpt}
                            clearable
                            search
                            onChange={(e, { value }) => setShowroomLockerData(value)}
                        />
                        <Button content="Show Locker Data" icon="table" color="blue" onClick={getLocker}/> 
                    </Col>
                    <Col xl={10} lg={12}>
                        <Row className="d-flex justify-content-start">
                            {
                                lockerData?.map((item, i) => (
                                <Col xl={3} lg={4} className="mb-4">
                                    <div >
                                        <div className={item.available === 'Y' ? 'hover-component-locker' : 'hover-component-locker-disable'}>
                                            <div className="hover-content-locker text-center pt-2">
                                                {
                                                    item.available === 'Y' ?
                                                    <>
                                                        <Button circular icon="edit" size="tiny" color="teal" onClick={() => handleClickEdit(item)}/>
                                                        <Button circular icon="trash alternate" size="tiny" color="teal" onClick={() => handleSubmitDelete(item.id)}/>
                                                        <Button circular icon="plus" content="Checkin" size="tiny" color="orange" className="ms-3"/>
                                                    </>
                                                    : 
                                                    <Button circular icon="close" content='Checkout' size="tiny" color="orange" className="ms-3"/>
                                                }
                                            </div>
                                            <div className={item.available === 'Y' ? 'locker-text-status' : 'locker-text-status-disable'}>
                                                {item.available === 'Y' ? <><Icon name="check"/><span>Available</span></> : <><Icon name="close circle"/><span>Booked</span></>}
                                            </div>
                                            <div className={item.available === 'Y' ? 'locker-number' : 'locker-number-disable'}>
                                                {item.locker_number}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                ))
                            }
                            <Col xl={3} lg={4}>
                                <div style={{ border: '2px dashed #13a89e',borderRadius:'5px', maxWidth: '240px', minWidth:'240px', minHeight:'240px' }} className="d-flex justify-content-center">
                                    <div style={{ cursor: 'pointer' }} className="d-flex align-items-center">
                                        <Icon size="huge" color="teal" name="plus" onClick={handleClickAddLocker} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAddLocker} onClose={() => setModalAddLocker(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New Locker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmLocker" onSubmit={handleSubmitAddLocker}>
                        <Form.Field>
                            <label>Showroom</label>
                            <Dropdown
                                selection
                                options={showroomOpt}
                                clearable
                                search
                                value={showroom}
                                onChange={(e, { value }) => setShowroom(value)}
                            />
                        </Form.Field>
                        <Form.Group grouped>
                            <Form.Input type="text" label="Locker Number" maxLength={2} placeholder="24" value={lockNumber} onChange={(e) => setLockNumber(e.target.value)} />
                            <Form.Input type="text" label="Description" placeholder="Write some desription if needed" value={desc} onChange={(e) => setDesc(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmLocker" type="submit" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Locker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Field>
                            <label>Showroom</label>
                            <Dropdown
                                disabled
                                selection
                                options={showroomOpt}
                                clearable
                                search
                                value={showroom}
                                onChange={(e, { value }) => setShowroom(value)}
                            />
                        </Form.Field>
                        <Form.Group grouped>
                            <Form.Input type="text" label="Locker Number" maxLength={2} placeholder="24" value={lockNumber} onChange={(e) => setLockNumber(e.target.value)} />
                            <Form.Input type="text" label="Description" placeholder="Write some desription if needed" value={desc} onChange={(e) => setDesc(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmEdit" type="submit" color="blue" />
                </Modal.Footer>
            </Modal>
        </>
    )
}