import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Modal from "rsuite/Modal"
import { Button, Dropdown, Icon, Input, Label, Popup, Radio, Transition } from "semantic-ui-react";
import { Reacttable } from "../component/Reacttable";
import { copyTextToClipboard, curency, curencyNoSym } from "../component/utils";
import Stack from "react-bootstrap/Stack";
import Badge from "rsuite/Badge";
import { Toaster } from "../component/Toaster";

export const Payment = () => {
    const { host, userProfile, supplierOpt } = useMyGlobal()
    const [modalCreate, setModalCreate] = useState(false)
    const [supplier, setSupplier] = useState('')
    const [payCash, setPayCash] = useState({ value: 0, desc: '', date: '' })
    const [payTransfer, setPayTransfer] = useState({ value: 0, desc: '', date: '' })
    const [payGiro, setPayGiro] = useState({ value: 0, desc: '', date: '' })
    const [invoiceData, setInvoiceData] = useState([])
    const tableRef = useRef()
    const [toPaid, setToPaid] = useState(0)
    const [show, setShow] = useState(false)
    const [selectedInv, setSelectedInv] = useState([])
    const [note, setNote] = useState('credit')
    const [noteValue, setNoteValue] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [apPaymentData, setApPaymentData] = useState([])
    const [modalDetail, setModalDetail] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [modalBank, setModalBank] = useState(false)
    const [dataBank, setDataBank] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)

    const columnsInv = useMemo(() => [
        {
            Header: 'Supplier',
            accessor: 'header.vendor_name'
        },
        {
            Header: 'Invoice',
            accessor: 'header.inv_number'
        },
        {
            Header: 'Inv. Date',
            accessor: 'header.inv_date'
        },
        {
            Header: 'Due Date',
            accessor: 'header.due_date',
        },
        {
            Header: 'TOP',
            accessor: 'header.top',
            Cell: ({ cell }) => (`${cell.value} Days`)
        },
        {
            Header: 'Qty',
            accessor: 'header.qty_receipt',
        },
        {
            Header: 'Total Invoice',
            accessor: 'header.total_invoice',
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: 'Created By',
            accessor: 'header.user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '0.9em' }}>
                    <div >{cell.value}</div>
                    <div className="text-muted">{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Status',
            accessor: 'header.status',
            Cell: ({ cell }) => (
                <Stack direction="horizontal" gap={2}>
                    <Badge color={cell.value === 'unpaid' ? 'red' : 'green'} />
                    <div className="text-capitalize">{cell.value}</div>
                </Stack>
            )
        },
    ])

    const columnsPayment = useMemo(() => [
        {
            Header: 'Payment No',
            accessor: 'header.id_payment'
        },
        {
            Header: 'Supplier',
            accessor: 'header.vendor_name',
            Cell: ({ cell }) => (
                <div style={{ width: 180 }}>
                    <div className="text-truncate">{cell.value}</div>
                </div>
            )
        },
        {
            Header: 'Cash',
            className: 'parent-header',
            columns: [
                {
                    Header: 'Cash',
                    accessor: 'header.cash_value',
                    Cell: ({ cell }) => <div>{curencyNoSym.format(cell.value)}</div>
                },
                {
                    Header: 'Description',
                    accessor: 'header.cash_desc',
                },
                {
                    Header: 'Date',
                    accessor: 'header.cash_date',
                },
            ],
        },
        {
            Header: 'Transfer',
            accessor: 'header.trf_value',
            className: 'parent-header',
            columns: [
                {
                    Header: 'Cash',
                    accessor: 'header.trf_value',
                    Cell: ({ cell }) => <div>{curencyNoSym.format(cell.value)}</div>
                },
                {
                    Header: 'Description',
                    accessor: 'header.trf_desc',
                },
                {
                    Header: 'Date',
                    accessor: 'header.trf_date',
                },
            ],
        },
        {
            Header: 'Giro',
            className: 'parent-header',
            accessor: 'header.giro_value',
            columns: [
                {
                    Header: 'Cash',
                    accessor: 'header.giro_value',
                    Cell: ({ cell }) => <div>{curencyNoSym.format(cell.value)}</div>
                },
                {
                    Header: 'Description',
                    accessor: 'header.giro_desc',
                },
                {
                    Header: 'Date',
                    accessor: 'header.giro_date',
                },
            ],
        },
        {
            Header: 'Note',
            accessor: 'header.note_type',
            Cell: ({ cell }) => (<div className="text-capitalize">{cell.value}</div>)
        },
        {
            Header: 'Note Value',
            accessor: 'header.note_value',
            Cell: ({ cell }) => <div>{curencyNoSym.format(cell.value)}</div>
        },
        {
            Header: 'Paid',
            accessor: 'header.paid',
            Cell: ({ cell }) => <div>{curencyNoSym.format(cell.value)}</div>
        },
        {
            Header: 'Create By',
            accessor: 'header.user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '0.8em' }}>
                    <div>{cell.value}</div>
                    <div className="text-muted">{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <div className="text-center">
                    <Popup content="Detail" size="tiny" trigger={
                        <Icon name="list layout" style={{ cursor: 'pointer' }} color="blue" onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                </div>
            )
        },
    ])

    const columnsDetail = useMemo(() => [
        {
            Header: 'Inv Number',
            accessor: 'inv_number'
        },
        {
            Header: 'Inv Date',
            accessor: 'inv_date'
        },
        {
            Header: 'PO Type',
            accessor: 'po_type',
            Cell:({cell}) => (<div className="text-capitalize">{cell.value}</div>)
        },
        {
            Header: 'PPN',
            accessor: 'ppn',
            Cell:({cell}) => (<div>{cell.value} %</div>)
        },
        {
            Header: 'Total PPN',
            accessor: 'total_ppn',
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: 'DPP',
            accessor: 'total_dpp',
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: 'Qty',
            accessor: 'qty_receipt',
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: 'Total Invoice',
            accessor: 'total_invoice',
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
    ])

    const columnsBank = useMemo(() => [
        {
            Header: '#',
            accessor: 'id'
        },
        {
            Header: 'Bank',
            accessor: 'name'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Create By',
            accessor: 'user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '1em' }}>
                    <div>{cell.value}</div>
                    <div className="text-muted">{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Select" size="tiny" trigger={
                        <Button icon={{ name: 'arrow alternate circle right outline', color: 'blue' }} onClick={() => handleSelectBank(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleSelectBank = (data) => {
        setSelectedBank(data)
        setModalBank(false)
        setPayTransfer(prev => ({ ...prev, desc: `${data.name} ${data.description}`}))
    }

    const handleGetSelected = () => {
        if (tableRef.current) {
            const selected = tableRef.current.getSelectedRows()
            const toPaidTemp = selected.reduce((total, cur) => total + parseFloat(cur.header.total_invoice), 0)
            setToPaid(toPaidTemp)
            setSelectedInv(selected)
            setShow(true)
        }
    }

    const getBank = async () => {
        await axios({
            method: 'GET',
            url: `${host}/payment/?action=bankMaster`,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataBank(res.data.data)
            }
        })
    }

    const getInvoiceData = async (idSupplier) => {
        await axios({
            method: "GET",
            url: `${host}/payment/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                id_supplier: idSupplier,
                action: 'invoiceBySupplier'
            }
        }).then(res => {
            if (res.data.status === 200) {
                const filteredInv = res.data.data.filter(i => i.header.status.toLowerCase() === 'unpaid')
                setInvoiceData(filteredInv)
                if (filteredInv.length === 0) {
                    Toaster.fire({
                        icon: 'warning',
                        text: 'No Payable Invoice Found',
                        position: 'top-right'
                    })
                }
            }
        })
    }

    const handleClickCreatePay = () => {
        setModalCreate(true)
    }

    const handleSelectSupplier = (value) => {
        setSupplier(value)
        getInvoiceData(value)
    }

    const handleInputNoteValue = (e) => {
        const value = e.target.value
        setNoteValue(value)
        if (value !== '') {
            if (note === 'credit') {
                setTotalPaid(toPaid + parseFloat(value))
            } else if (note === 'debit') {
                setTotalPaid(toPaid - parseFloat(value))
            }
        }
    }

    const clearForm = () => {
        setSupplier('')
        setSelectedInv([])
        setPayCash({ value: 0, desc: '', date: '' })
        setPayTransfer({ value: 0, desc: '', date: '' })
        setPayGiro({ value: 0, desc: '', date: '' })
        setNote('credit')
        setNoteValue(0)
        setToPaid(0)
        setInvoiceData([])
        setShow(false)
    }

    const handleSubmit = () => {
        const cfm = window.confirm("Are you sure ?")
        const curTotal = parseFloat(toPaid) + (note === 'credit' ? parseFloat(-noteValue) : parseFloat(noteValue))
        if (cfm) {
            if (totalPaid === curTotal) {
                const header = {
                    supplier: supplier,
                    pay_cash: payCash,
                    pay_transfer: payTransfer,
                    pay_giro: payGiro,
                    note_type: note,
                    note_value: noteValue,
                    to_paid: totalPaid,
                    id_user: userProfile.profile.id_user,
                }

                const detail = selectedInv.map(i => i.header)
         
                axios({
                    method: "POST",
                    url: `${host}/payment/index.php?action=createPayablePayment`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: {
                        header,
                        detail
                    }
                }).then(res => {
                    if (res.data.status === 200) {
                        Toaster.fire({
                            icon: 'success',
                            text: 'Success create payment',
                            position: 'top-right'
                        })
                        setModalCreate(false)
                        clearForm()
                        getPayablePayment()
                    }
                })
            } else {
                Toaster.fire({
                    icon: 'warning',
                    title: 'Invalid total payment',
                    text: 'You have to ensure that the payments are distributed correctly',
                    position: 'top-right'
                })
            }
        }

    }

    const getPayablePayment = async () => {
        await axios({
            method: 'GET',
            url: `${host}/payment/index.php?action=apPayment`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                setApPaymentData(res.data.data)
            }
        })
    }

    const handleClickDetail = (data) => {
        setSelectedRow(data)
        setModalDetail(true)
    }

    const handleBrowseBank = () => {
        getBank()
        setModalBank(true)
    }

    useEffect(() => {
        if (note === 'credit') {
            setTotalPaid(toPaid - parseFloat(noteValue))
        } else if (note === 'debit') {
            setTotalPaid(toPaid + parseFloat(noteValue))
        }
    }, [note])

    useEffect(() => {
        if (noteValue > 0) {
            if (note === 'credit') {
                setTotalPaid(toPaid - parseFloat(noteValue))
            } else if (note === 'debit') {
                setTotalPaid(toPaid + parseFloat(noteValue))
            }
        }
    }, [noteValue])

    useEffect(() => {
        const total = parseFloat(payCash.value) + parseFloat(payTransfer.value) + parseFloat(payGiro.value) + (note === 'credit' ? parseFloat(-noteValue) : parseFloat(noteValue))
        setTotalPaid(total)
    }, [payCash, payTransfer, payGiro])

    useEffect(() => {
        getPayablePayment()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Payable Invoice Payment</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} className="mb-3">
                        <Button content="Create New Payment" icon="add" onClick={handleClickCreatePay} color="blue" />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable
                            data={apPaymentData}
                            columns={columnsPayment}
                            pageRow={10}
                            noData={apPaymentData.length === 0 ? true : false}
                            bordered={true}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} backdrop="static" overflow={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Create New Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12} md={12} className="mb-3">
                            <div className="mb-1">Supplier</div>
                            <Dropdown
                                search
                                options={supplierOpt}
                                selection
                                placeholder="Select Supplier"
                                style={{ width: '350px' }}
                                fluid
                                value={supplier}
                                onChange={(e, { value }) => handleSelectSupplier(value)}
                            />
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Reacttable
                                data={invoiceData}
                                columns={columnsInv}
                                pageRow={5}
                                noData={invoiceData.length === 0 ? true : false}
                                totals={['header.total_invoice', 'header.qty_receipt']}
                                checkbox
                                ref={tableRef}
                            />
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12} xs={12} className="text-start">
                            <Button content="Confirm Selected" color="blue" onClick={handleGetSelected} />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12} className="text-start" style={{ overflowX: 'scroll' }}>
                            {
                                selectedInv.map((item, i) => (
                                    <Label basic color="orange" circular key={i}>
                                        {item.header.inv_number}
                                        {/* <Icon name='delete' /> */}
                                    </Label>
                                ))
                            }
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-end">
                            <span>Total to Paid : </span>
                            <Label basic size="large" circular color="orange" content={curency.format(toPaid)} />
                            <Popup size="tiny" content="Copy to Clipboard" trigger={<Icon name="copy outline" style={{ cursor: 'pointer' }} onClick={() => copyTextToClipboard(toPaid)} />} />
                        </Col>
                        <Transition visible={show} animation='scale' duration={500}>
                            <Col xl={12} lg={12} md={12} >
                                <Row className="mt-3">
                                    <Col xl={3} lg={3}>
                                        <div className="fw-bold mb-2">Cash</div>
                                        <Input type="number" size="small" fluid placeholder="Payment with Cash" className="mb-2" value={payCash.value} onChange={(e) => setPayCash(prev => ({ ...prev, value: e.target.value }))} />
                                        <Input type="text" maxLength={40} size="small" fluid placeholder="Description" className="mb-2" value={payCash.desc} onChange={(e) => setPayCash(prev => ({ ...prev, desc: e.target.value }))} />
                                        <Input type="date" fluid className="mb-2" value={payCash.date} onChange={(e) => setPayCash(prev => ({ ...prev, date: e.target.value }))} />
                                    </Col>
                                    <Col xl={3} lg={3}>
                                        <div className="fw-bold mb-2" >Bank Transfer</div>
                                        <Input type="number" size="small" fluid placeholder="Payment with Bank Transfer" className="mb-2" value={payTransfer.value} onChange={(e) => setPayTransfer(prev => ({ ...prev, value: e.target.value }))} />
                                        <Input 
                                            type="text" 
                                            maxLength={40} 
                                            size="small" 
                                            fluid 
                                            placeholder="Description" 
                                            className="mb-2" 
                                            value={payTransfer.desc} onChange={(e) => setPayTransfer(prev => ({ ...prev, desc: e.target.value }))}
                                            action={{icon:'ellipsis horizontal', color:'blue', onClick:() => handleBrowseBank()}}    
                                            readOnly
                                        />
                                        <Input type="date" fluid className="mb-2" value={payTransfer.date} onChange={(e) => setPayTransfer(prev => ({ ...prev, date: e.target.value }))} />
                                    </Col>
                                    <Col xl={3} lg={3}>
                                        <div className="fw-bold mb-2">Giro</div>
                                        <Input type="number" size="small" fluid placeholder="Payment with Giro" className="mb-2" value={payGiro.value} onChange={(e) => setPayGiro(prev => ({ ...prev, value: e.target.value }))} />
                                        <Input type="text" maxLength={40} size="small" fluid placeholder="Description" className="mb-2" value={payGiro.desc} onChange={(e) => setPayGiro(prev => ({ ...prev, desc: e.target.value }))} />
                                        <Input type="date" fluid className="mb-2" value={payGiro.date} onChange={(e) => setPayGiro(prev => ({ ...prev, date: e.target.value }))} />
                                    </Col>
                                    <Col xl={3} lg={3}>
                                        <div className="fw-bold mb-2">Note</div>
                                        <div className="mb-1">
                                            <Radio
                                                checked={note === 'debit'}
                                                value='debit'
                                                label="Debit Note"
                                                onChange={(e, { value }) => setNote(value)}
                                            /><br />
                                            <Radio
                                                checked={note === 'credit'}
                                                value="credit"
                                                label="Credit Note"
                                                onChange={(e, { value }) => setNote(value)}
                                            />
                                        </div>
                                        <Input type="number" size="small" min={0} fluid placeholder={note === 'credit' ? 'Credit Value' : 'Debit Value'} className="mb-2" value={noteValue} onChange={handleInputNoteValue} />
                                        <Input size="small" fluid placeholder="Total Paid" readOnly value={curencyNoSym.format(totalPaid)} style={{ fontWeight: 'bold' }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Transition>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" onClick={handleSubmit} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Item Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable 
                        data={selectedRow?.detail}
                        columns={columnsDetail}
                        pageRow={10}
                        noData={selectedRow?.detail?.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalBank} onClose={() => setModalBank(false)} backdrop="static" overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Bank List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={dataBank}
                        columns={columnsBank}
                        noData={dataBank.length === 0 ? true : false}
                        pageRow={10}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}