import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { sha256, useDeviceInformation } from "../component/utils";
import { Toaster } from "../component/Toaster";
import logo from "../asset/javafootwear.png";
import Image from "react-bootstrap/Image"
import { useGeolocated } from 'react-geolocated';
import UAParser from 'ua-parser-js';

export const Login = () => {
    const [visible, setVisible] = useState(false)
    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const { host } = useMyGlobal()
    const navigate = useNavigate()
    const [remEmail, setRemEmail] = useState(false)
    const [lat, setLat] = useState(null)
    const [long, setLong] = useState(null)
    const [deviceInfo, setDeviceInfo] = useState(null)
    const [loadLogin, setLoadLogin] = useState(false)

    const handleVis = () => (setVisible(!visible))

    const {
        isGeolocationAvailable,
        isGeolocationEnabled,
        coords
    } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    })

    const handleSubmit = () => {
        setLoadLogin(true)
        const param = new FormData()
        param.append('email', email)
        param.append('password', pwd)
        param.append('action', 'login')
        if (coords){
            axios({
                method: 'POST',
                url: `${host}/log/index.php`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
                },
                data: param
            }).then(res => { 
                if (res.data.status === 200) {
                    let data = res.data.data
                    try {
                        if (typeof data !== 'undefined') {
                            sha256(pwd).then(sha => Cookies.set('_erpLog', JSON.stringify(data), { expires: 1 }))
                            createLog(data.profile.id_user)
                            updateStatusLog(data.profile.id_user, 'online')
                            if (remEmail) {
                                window.localStorage.setItem('log_email', email)
                            } else {
                                window.localStorage.removeItem('log_email')
                            }
                        }
                    } catch (error) {
                        Toaster.fire({
                            icon: 'error',
                            text: 'Error when store data on cookies',
                            position: 'top'
                        })
                    }
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: 'Login Failed',
                        position: 'top'
                    })
                }
                setLoadLogin(false)
            })
        } else {
            alert("Oops kelihatanya kamu tidak ijinkan aplikasi ini mengakses lokasi...!\n\nSebaiknya kamu ijinkan aplikasi ini untuk mengakses lokasi\nkami hanya ingin mengamankan aplikasi kami\nsilahkan cek pengaturan pada browser cari opsi privacy ijinkan aplikasi ini.")
        }
    }

    const createLog = (id_user) => {
        const data = {
            id_user:id_user,
            lat:coords.latitude,
            long:coords.longitude,
            browser:deviceInfo.browser.name,
            os:`${deviceInfo.os.name} ${deviceInfo.os.version}`,
        }

        const param = new FormData()
        param.append('data', JSON.stringify(data))
        param.append('action', 'createLog')

        axios({
            method: 'POST',
            url: `${host}/log/index.php`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data: param
        })
    }

    const updateStatusLog = (id, status) => {
        const param = {
            id_user:id,
            status:status
        }
        axios({
            method: 'PUT',
            url: `${host}/log/index.php?action=updateStatusLog`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data:param
        }). then (res => {
            window.location.reload()
        })
    }
  
    useEffect(() => {
        if (!coords){
            console.log('Not Allowed')
        } else {
            setLat(coords.latitude)
            setLong(coords.longitude)
        }
    },[coords])

    useEffect(() => {
        setEmail(localStorage.getItem('log_email'))
        if (localStorage.getItem('log_email')) {
            setRemEmail(true)
        }
        const parser = new UAParser()
        const result = parser.getResult()
        setDeviceInfo(result)
    }, [])
    
    return (
        <>
            <Container className="vh-100">
                <Row className="d-flex justify-content-center" style={{ marginTop: '10%' }}>
                    <Col xl={3} lg={4} sm={6}>
                        <div className="text-center mb-3">
                            <Image src={logo} width={80} height={45} />
                        </div>
                        <div className="mb-4 text-center">
                            <div style={{ fontSize: '1.2em' }}>PT. JAVA FOOTWEAR SPORTINDO</div>
                            <div style={{ fontSize: '0.8em', color: '#e85424' }}>ENTERPRISE RESOURCE PLANNING</div>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <Form.Field>
                                <Form.Input icon='at' label='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </Form.Field>
                            <Form.Field className="mb-4">
                                <Form.Input icon={<Icon name={visible ? 'eye slash' : 'eye'} link onClick={handleVis} />} label='Password' type={visible ? 'text' : 'password'} onChange={(e) => sha256(e.target.value).then(sha => setPwd(sha))} required />
                                <Form.Checkbox label="Remember Email" checked={remEmail} onChange={(e, data) => setRemEmail(data.checked)} />
                            </Form.Field>
                            <Form.Field>
                                <Button content="Login" color="black" icon="sign-in" fluid labelPosition="right" type="submit" loading={loadLogin} disabled={loadLogin}/>
                            </Form.Field>
                        </Form>
                        <div className="text-center mt-5" style={{ fontSize: '0.9em' }}>
                            <span>&copy;2024.</span>
                            <span style={{ color: '#e85424' }}> JFS Developer.</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}