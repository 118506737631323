import React, { useEffect, useState } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { Menu, MenuItem, SubMenu, ProSidebar, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar'
import { Link } from "react-router-dom";
import { BsBagPlus, BsBarChart, BsBox, BsCart, BsCashCoin, BsChevronDoubleLeft, BsChevronDoubleRight, BsClipboardData, BsGear, BsGift, BsGraphUp, BsHouse, BsPower } from "react-icons/bs";
import Cookies from "js-cookie";
import { Button, Icon, Popup } from "semantic-ui-react";
import Stack from "react-bootstrap/Stack";
import axios from "axios";
import { useMyGlobal } from "./Global";

export const SidebarComponent = () => {
    const [collapsed, setCollapsed] = useState(false)
    const [userProf, setUserProf] = useState(null)
    const {host} = useMyGlobal()

    const handleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    const handleLogout = () => {
        Cookies.remove('_erpLog')
        updateStatusLog(userProf.profile.id_user, 'offline')
        window.location.reload()    
    }

    const updateStatusLog = (id, status) => {
        const param = {
            id_user:id,
            status:status
        }
        axios({
            method: 'PUT',
            url: `${host}/log/index.php?action=updateStatusLog`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data:param
        })
    }

    useEffect(() => {
        setUserProf(JSON.parse(Cookies.get('_erpLog')))
    },[])


    return (
        <>
            <ProSidebar
                collapsed={collapsed}
                style={{ height:'100vh'}}
            >
                <SidebarHeader>
                    <Menu iconShape="circle">
                        {collapsed ? (
                            <MenuItem
                                icon={<BsChevronDoubleRight />}
                                onClick={handleCollapsed}
                            ></MenuItem>
                        ) : (
                            <MenuItem
                                suffix={<BsChevronDoubleLeft />}
                                onClick={handleCollapsed}
                            >
                                <div
                                    style={{
                                        textAlign: "left",
                                        color: '#EC551D',
                                        textTransform: 'uppercase',
                                        fontWeight: 800,
                                        fontSize: 15,
                                        letterSpacing: '2px'
                                    }}
                                >
                                    PT. JAVA FOOTWEAR
                                </div>
                                <div style={{ fontSize: 10, textAlign: 'left', textTransform: 'uppercase', fontWeight: 500 }}>
                                    Enterprise Resource Planning
                                </div>
                            </MenuItem>
                        )}
                    </Menu>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="round">
                        {
                            userProf?.access?.some(item => item.id_page === '1') ?
                            <MenuItem icon={<BsBarChart />} suffix={<Link to="/"/>}>Dashboard</MenuItem>
                            : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '2') ?
                            <SubMenu title="Product" icon={<BsBox/>}>
                                <MenuItem suffix={<Link to="/product/master_article"/>}>Master Article</MenuItem>
                                <MenuItem suffix={<Link to="/product/discount"/>}>Discount</MenuItem>
                                <MenuItem suffix={<Link to="/product/brand"/>}>Brand</MenuItem>
                            </SubMenu>
                            : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '3') ?
                            <SubMenu title="Purchasing" icon={<BsBagPlus />} >
                                <MenuItem suffix={<Link to="/purchasing/master_vendor"/>}>Master Vendor</MenuItem>
                                <MenuItem suffix={<Link to="/purchasing/purchase_order"/>}>Purchase Order</MenuItem>
                                <MenuItem suffix={<Link to="/purchasing/goods_receipt"/>}>Goods Receipt</MenuItem>
                            </SubMenu>
                            : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '10') ?
                            <SubMenu title="Payment" icon={<BsCashCoin />} >
                                <MenuItem suffix={<Link to="/payment/master_bank"/>}>Master Bank</MenuItem>
                                <MenuItem suffix={<Link to="/payment/payable_invoice"/>}>Payable Invoice</MenuItem>
                                <MenuItem suffix={<Link to="/payment/payable_invoice_payment"/>}>Payable Invoice (Payment)</MenuItem>
                            </SubMenu>
                            : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '4') ?
                            <SubMenu title="Showroom" icon={<BsHouse/>}>
                                <MenuItem suffix={<Link to="/showroom/warehouse"/>}>Warehouse</MenuItem>
                                <MenuItem suffix={<Link to="/showroom/showroom"/>}>Showroom</MenuItem>
                            </SubMenu>
                            : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '5') ?
                            <SubMenu title="Stock" icon={<BsGraphUp />}>
                                <MenuItem suffix={<Link to="/stock/product_stock"/>}>Product Stock</MenuItem>
                                <MenuItem suffix={<Link to="/stock/stock_transfer"/>}>Stock Transfer</MenuItem>
                                <MenuItem suffix={<Link to="/stock/stock_adjustment"/>}>Stock Adjustment</MenuItem>
                                <MenuItem suffix={<Link to="/stock/stock_movement"/>}>Stock Movement</MenuItem>
                            </SubMenu>
                            : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '6') ?
                            <SubMenu title="Sales" icon={<BsCart />}>
                                {
                                    userProf?.profile.id_role === 'R24060003' ||  userProf?.profile.id_role === 'R24070005' ? // KASIR
                                    <MenuItem suffix={<Link to="/sales/pos"/>}>Sales Entry POS</MenuItem>
                                    :<></>
                                }
                                <MenuItem suffix={<Link to="/sales/agent"/>}>Sales Agent</MenuItem>
                                <MenuItem suffix={<Link to="/sales/master_edc"/>}>Master EDC</MenuItem>
                                <MenuItem suffix={<Link to="/sales/master_cc"/>}>Master Credit Card</MenuItem>
                                <MenuItem suffix={<Link to="/sales/voucher"/>}>Voucher</MenuItem>
                            </SubMenu>
                            :<></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '8') ?
                            <SubMenu title="Report" icon={<BsClipboardData />}>
                                <MenuItem suffix={<Link to="/report/sales"/>}>POS Sales Report</MenuItem>
                            </SubMenu>
                            :<></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '9') ?
                            <SubMenu title="Loyalty Program" icon={<BsGift />}>
                                <MenuItem suffix={<Link to="/loyalty_program/membership"/>}>Membership</MenuItem>
                                <MenuItem suffix={<Link to="/loyalty_program/lockerPage"/>}>Locker</MenuItem>
                            </SubMenu>
                            :<></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '7') ?
                            <SubMenu title="Administrator" icon={<BsGear />}>
                                <MenuItem suffix={<Link to="/administrator/user"/>}>User</MenuItem>
                                <MenuItem suffix={<Link to="/administrator/role"/>}>Role</MenuItem>
                                <MenuItem suffix={<Link to="/administrator/announcement"/>}>Announcement</MenuItem>
                            </SubMenu>
                            : <></>
                        }
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <Menu iconShape="circle">
                        <MenuItem icon={<Popup trigger={<BsPower/>} content="Logout" color="black" size="small"/>} onClick={handleLogout}>
                            <Stack direction="vertical">
                                <div>Sign as {userProf?.profile.name}</div>
                                <div className="text-capitalize" style={{fontSize:'0.8em', color:'coral'}}>{userProf?.profile.role_name}</div>
                            </Stack>
                        </MenuItem>
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
        </>
    )
}