import React, { useEffect, useMemo } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Form, Popup } from "semantic-ui-react";
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { useState } from "react";
import Modal from "rsuite/Modal"
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";

export const MasterBank = () => {
    const { host, userProfile } = useMyGlobal()
    const [bankName, setBankName] = useState('')
    const [bankDesc, setBankDesc] = useState('')
    const [modalCreate, setModalCreate] = useState(false)
    const [dataBank, setDataBank] = useState([])
    const [modalEdit, setModalEdit] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const columns = useMemo(() => [
        {
            Header: '#',
            accessor: 'id'
        },
        {
            Header: 'Bank',
            accessor: 'name'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Create By',
            accessor: 'user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '1em' }}>
                    <div>{cell.value}</div>
                    <div className="text-muted">{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell:({cell}) => (
                <Button.Group basic size="mini">
                    <Popup content="Edit" size="tiny" trigger={
                        <Button icon={{name:'edit', color:'blue'}} onClick={() => handleClickEdit(cell.row.original)}/>
                    }/>
                </Button.Group>
            )
        },
    ])

    const handleClickEdit = (data) => {
        setSelectedRow(data)
        setBankName(data.name)
        setBankDesc(data.description)
        setModalEdit(true)
    }

    const clearForm = () => {
        setBankName('')
        setBankDesc('')
    }

    const getBank = async () => {
        await axios({
            method: 'GET',
            url: `${host}/payment/?action=bankMaster`,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataBank(res.data.data)
            }
        })
    }

    const handleSubmit = () => {
        const param = new FormData()

        param.append('name', bankName)
        param.append('desc', bankDesc)
        param.append('id_user', userProfile.profile.id_user)

        axios({
            method: 'POST',
            url: `${host}/payment/?action=createMasterBank`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success add new Bank',
                    position: 'top-right'
                })
                clearForm()
                setModalCreate(false)
                getBank()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleSubmitEdit = () => {
        if (selectedRow){
            const param = {
                name:bankName,
                desc:bankDesc,
                id:selectedRow?.id,
            }
            axios({
                method: 'PUT',
                url: `${host}/payment/?action=editBank`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:'Success update Bank',
                        position: 'top-right',
                    })
                    clearForm()
                    setModalEdit(false)
                    getBank()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:'top-right',
                    })
                }
            })
        } else {
            Toaster.fire({
                icon:'warning',
                text:'You have not selected bank data',
                position:'top-right'
            })
        }
    }

    const handleClickAdd = () => {
        setModalCreate(true)
    }

    useEffect(() => {
        getBank()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master Bank</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={8} lg={10} md={12} sm={12} className="mb-3">
                        <Button content="Add New Bank" color="blue" icon="plus" onClick={handleClickAdd} />
                    </Col>
                    <Col xl={8} lg={10} md={12} sm={12}>
                        <Reacttable
                            data={dataBank}
                            columns={columns}
                            pageRow={10}
                            noData={dataBank.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Add New Master Bank</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmCreate" onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <Form.Input type="text" label="Bank Name" value={bankName} onChange={(e) => setBankName(e.target.value)} required />
                            <Form.Input type="text" label="Description" value={bankDesc} onChange={(e) => setBankDesc(e.target.value)} required />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" content="Submit" form="frmCreate" color="blue" icon="plus" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Master Bank</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmCreate" onSubmit={handleSubmitEdit}>
                        <Form.Group grouped>
                            <Form.Input type="text" label="Bank Name" value={bankName} onChange={(e) => setBankName(e.target.value)} required />
                            <Form.Input type="text" label="Description" value={bankDesc} onChange={(e) => setBankDesc(e.target.value)} required />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" content="Submit" form="frmCreate" color="blue" icon="plus" />
                </Modal.Footer>
            </Modal>
        </>
    )
}