import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "./Global";

export const Announcement = () => {
    const [anc, setAnc] = useState([]) 
    const {host} = useMyGlobal()

    const getAnnouncement = async () => {
        await axios({
            method: "GET",
            url: `${host}/log/index.php?action=announcement`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then (res => {
            if (res.data.status === 200){
                setAnc(res.data.data.filter(i => i.is_active === 'Y'))
            }
        })
    }

    useEffect(() => {
        getAnnouncement()
    },[])

    return (
        <div style={{ bottom: '0px', position: 'absolute', width: '100vw', background: '#1d1d1d' }} className='p-1 sroll-container'>
            <div className='scroll-text'>
                {
                    anc.map((item, i) => (
                        <span style={{ color: 'whitesmoke', fontSize: '0.9em' }} className='me-5' key={i}>
                            from <span style={{color:'coral'}}>{item.user_name}</span> : {item.text}
                        </span>
                    ))
                }
            </div>
        </div>
    )
}