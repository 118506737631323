import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { Button, Form, Popup } from "semantic-ui-react";
import { Modal } from "rsuite";
import { useMyGlobal } from "../component/Global";
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";
import Toggle from "rsuite/Toggle"

export const Showroom = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [name, setName] = useState('')
    const [abr, setAbr] = useState('')
    const [city, setCity] = useState('')
    const [province, setProvince] = useState('')
    const {cityOpt, provOpt, host} = useMyGlobal()
    const [address, setAddress] = useState('')
    const [supervisor, setSupervisor] = useState('')
    const [manager, setManager] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [warehouse, setWarehouse] = useState('')
    const [category, setCategory] = useState('Mono Brand')
    const [status, setStatus] = useState('Y')
    const [warehouseOpt, setWarehouseOpt] = useState([])
    const [idShowroom, setIdShowroom] = useState('')
    const [loaderAdd, setLoaderAdd] = useState(false)
    const [loaderEdit, setLoaderEdit] = useState(false)
    const [dataShowroom, setDataShowroom] = useState([])

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_showroom"
        },
        {
            Header: "Name",
            accessor: "showroom_name"
        },
        {
            Header: "Abr",
            accessor: "showroom_abr"
        },
        {
            Header: "Category",
            accessor: "category"
        },
        {
            Header: "City",
            accessor: "city"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Active",
            accessor: "status",
            Cell: ({ cell }) => (
                <Toggle size="sm" defaultChecked={cell.value === 'Y' ? true : false} onChange={() => handleChangeStatus(cell.value, cell.row.original.id_showroom)} />
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell : ({cell}) => (
                <Button.Group basic size="mini">
                    <Popup content="Edit" size="mini" trigger = {
                        <Button icon={{name:'edit outline', color:'blue'}} onClick={() => handleClickEdit(cell.row.original)}/>
                    }/>
                </Button.Group>
            )
        },

    ])

    const handleChangeStatus = (value, idShowroom) => {
        const status = value === 'Y' ? 'N' : 'Y'
        axios({
            method: "PUT",
            url: `${host}/showroom/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { id_showroom: idShowroom, status: status, action:'changeStatus' },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success change status',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleClickAdd = () => {
        setModalAdd(true)
        getDataWarehouse()
    }

    const handleChangeCity = (id) => {
        setCity(id)
        setProvince(provOpt?.find(item => item.value === id).text)
    }

    const getDataWarehouse  = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=warehouse`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                setWarehouseOpt(res.data.data.map(i => {
                    return {
                        key:i.id_warehouse,
                        text:i.desc,
                        value:i.id_warehouse,
                    }
                }))
            }
        })
    }

    const getDataShowroom = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=showroom`,
            headers: {
                "Content-Type": "application/json",
            }
        }). then (res => {
            if (res.data.status === 200){
                setDataShowroom(res.data.data)
            }
        }) 
    }

    const handleChangeCat = (e, { value }) => setCategory( value )
    const handleChangeType = (e, { value }) => setStatus( value )

    const handleSubmit = () => {
        setLoaderAdd(true)
        const param = new FormData()

        param.append('id_warehouse', warehouse)
        param.append('prov', province)
        param.append('city', cityOpt?.find(item => item.value === city).text)
        param.append('abr', abr)
        param.append('name', name)
        param.append('category', category)
        param.append('status', status)
        param.append('address', address)
        param.append('phone', phone)
        param.append('email', email)
        param.append('supervisor', supervisor)
        param.append('manager', manager)
        param.append('action', 'addShowroom')

        axios ({
            method: 'POST',
            url: `${host}/showroom/index.php?action=addShowroom`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: param
        }). then (res => {
            setLoaderAdd(false)
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success adding new showroom',
                    position:'top-right'
                })
                setModalAdd(false)
                getDataShowroom()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:'Failed adding new showroom',
                    position:'top-right'
                })
            }
        })
    }

    const handleClickEdit = (row) => { console.log(row)
        setIdShowroom(row.id_showroom)
        setWarehouse(row.id_warehouse)
        setName(row.showroom_name)
        setAbr(row.showroom_abr)
        setCity(cityOpt?.find(i => i.text === row.city).value)
        setProvince(row.province)
        setAddress(row.address)
        setSupervisor(row.supervisor)
        setPhone(row.phone)
        setEmail(row.email)
        setManager(row.manager)
        setCategory(row.category)
        setStatus(row.status)

        setModalEdit(true)
    }

    const handleUpdate = () => {
        setLoaderEdit(true)
        const param = {
            id_showroom: idShowroom,
            id_warehouse: warehouse,
            prov: province,
            city: cityOpt?.find(item => item.value === city).text,
            abr: abr,
            name: name,
            category: category,
            status: status,
            address: address,
            phone: phone,
            email: email,
            supervisor: supervisor,
            manager: manager,
            action: 'editShowroom',
        }

        axios ({
            method: 'PUT',
            url: `${host}/showroom/index.php?action=editShowroom`,
            headers: {
                "Content-Type": "application/json"
            },
            data: param
        }). then (res => {
            setLoaderEdit(false)
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success update showroom',
                    position:'top-right'
                })
                setModalEdit(false)
                getDataShowroom()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:'Failed update showroom',
                    position:'top-right'
                })
            }
        })
    }

    useEffect(() => {
        getDataShowroom()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Showroom</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={10} md={12} sm={12} className="mb-3">
                        <Button content="Add New Showroom" icon="add" onClick={handleClickAdd} color="blue"/>
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12}>
                        <Reacttable data={dataShowroom} columns={columns} pageRow={10} noData={dataShowroom.length === 0 ? true : false}/>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} backdrop="static" onClose={() => setModalAdd(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Add New Showroom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Input type="text" value={name} label="Showroom Name" width={11} placeholder="Hoops Homecourt" onChange={(e) => setName(e.target.value)} required/>
                            <Form.Input type="text" value={abr} label="Abr" width={5} placeholder="HHCT" onChange={(e) => setAbr(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Select
                                search
                                options={cityOpt}
                                required={true}
                                value={city}
                                label="City"
                                onChange={(e, {value}) => handleChangeCity(value)}
                            />
                            <Form.Input type="text" value={province} label="Province" readOnly/>
                        </Form.Group>
                        <Form.Input type="text" value={address} label="Address" onChange={(e) => setAddress(e.target.value)}/> 
                        <Form.Group group widths="equal">
                            <Form.Input type="text" label="Supervisor" value={supervisor} onChange={(e) => setSupervisor(e.target.value)}/>
                            <Form.Input type="text" label="Manager" value={manager} onChange={(e) => setManager(e.target.value)}/>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                            <Form.Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>
                        <Form.Select
                            required
                            search
                            options={warehouseOpt}
                            label="Warehouse"
                            value={warehouse}
                            onChange={(e,{value}) => setWarehouse(value)}
                        />
                        <Row>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Category</label>
                                    <Form.Radio 
                                        label="Mono Brand"
                                        value="Mono Brand"
                                        checked={category === "Mono Brand"}
                                        onChange={handleChangeCat}
                                    />
                                    <Form.Radio 
                                        label="Multi Brand"
                                        value="Multi Brand"
                                        checked={category === "Multi Brand"}
                                        onChange={handleChangeCat}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Status</label>
                                    <Form.Radio 
                                        label="Active"
                                        value="Y"
                                        checked={status === "Y"}
                                        onChange={handleChangeType}
                                    />
                                    <Form.Radio 
                                        label="Inactive"
                                        value="N"
                                        checked={status === "N"}
                                        onChange={handleChangeType}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-end mt-3">
                            <Button content="Save" type="submit" icon="save outline" loading={loaderAdd} disabled={loaderAdd}/>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal open={modalEdit} backdrop="static" onClose={() => setModalEdit(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Edit Showroom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUpdate}>
                        <Form.Group>
                            <Form.Input type="text" value={name} label="Showroom Name" width={11} placeholder="Hoops Homecourt" onChange={(e) => setName(e.target.value)} required/>
                            <Form.Input type="text" value={abr} label="Abr" width={5} placeholder="HHCT" onChange={(e) => setAbr(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Select
                                search
                                options={cityOpt}
                                required={true}
                                value={city}
                                label="City"
                                onChange={(e, {value}) => handleChangeCity(value)}
                            />
                            <Form.Input type="text" value={province} label="Province" readOnly/>
                        </Form.Group>
                        <Form.Input type="text" value={address} label="Address" onChange={(e) => setAddress(e.target.value)}/> 
                        <Form.Group group widths="equal">
                            <Form.Input type="text" label="Supervisor" value={supervisor} onChange={(e) => setSupervisor(e.target.value)}/>
                            <Form.Input type="text" label="Manager" value={manager} onChange={(e) => setManager(e.target.value)}/>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                            <Form.Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </Form.Group>
                        <Form.Select
                            required
                            search
                            options={warehouseOpt}
                            label="Warehouse"
                            value={warehouse}
                            onChange={(e,{value}) => setWarehouse(value)}
                        />
                        <Row>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Category</label>
                                    <Form.Radio 
                                        label="Mono Brand"
                                        value="Mono Brand"
                                        checked={category === "Mono Brand"}
                                        onChange={handleChangeCat}
                                    />
                                    <Form.Radio 
                                        label="Multi Brand"
                                        value="Multi Brand"
                                        checked={category === "Multi Brand"}
                                        onChange={handleChangeCat}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={6} sx={12}>
                                <Form.Group grouped>
                                    <label>Status</label>
                                    <Form.Radio 
                                        label="Active"
                                        value="Y"
                                        checked={status === "Y"}
                                        onChange={handleChangeType}
                                    />
                                    <Form.Radio 
                                        label="Inactive"
                                        value="N"
                                        checked={status === "N"}
                                        onChange={handleChangeType}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-end mt-3">
                            <Button content="Update" type="submit" icon="edit outline" loading={loaderEdit} disabled={loaderEdit} color="blue"/>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
}