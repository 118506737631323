import React, { useEffect, useMemo, useState } from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import { Button, Dropdown, Form, Input, Popup, Radio } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import moment from "moment";
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";

export const Membership = () => {
    const [memberData, setMemberData] = useState([])
    const { host, userProfile, cityOpt } = useMyGlobal()
    const [modalReg, setModalReg] = useState(false)
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [idenType, setIdenType] = useState('KTP')
    const [idenNumber, setIdenNumber] = useState('')
    const [city, setCity] = useState('')
    const [gender, setGender] = useState('Male')
    const [dob, setDob] = useState(moment().subtract(14, 'years').format('YYYY-MM-DD'))
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [modalEdit, setModalEdit] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const idenOpt = [
        { key: 1, text: 'KTP', value: 'KTP' },
        { key: 2, text: 'NISN', value: 'NISN' },
    ]

    const columnMember = useMemo(() => [
        {
            Header: "ID Member",
            accessor: "id_member"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Name",
            accessor: "first_name",
            Cell: ({ cell }) => (cell.value + ' ' + cell.row.original.last_name)
        },
        {
            Header: "Identity",
            accessor: "iden_type"
        },
        {
            Header: "Identity Number",
            accessor: "iden_number"
        },
        {
            Header: "DOB",
            accessor: "dob"
        },
        {
            Header: "Address",
            accessor: "address",
            Cell: ({ cell }) => (
                <div style={{ width: '100px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "City",
            accessor: "city_name",
            Cell: ({ cell }) => (
                <div style={{ width: '100px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Create At",
            accessor: "createAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup content="Edit" size="tiny" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup content="Delete" size="tiny" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleClickDelete(cell.row.original)} />
                    } />
                </Button.Group>
            )
        }
    ])

    const handleClickDelete = (data) => {
        const cfm = window.confirm("Are you sure..?")

        if (cfm) {
            axios({
                method: 'DELETE',
                url: `${host}/loyalty/index.php?action=deleteMember&id=${data.id_member}`,
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: "Success remove member",
                        position: 'top-right'
                    })
                    getMemberData()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const handleClickEdit = (data) => {
        console.log(data)
        setSelectedRow(data)
        setEmail(data.email)
        setFirstName(data.first_name)
        setLastName(data.last_name)
        setIdenType(data.iden_type)
        setIdenNumber(data.iden_number)
        setDob(data.dob)
        setAddress(data.address)
        setCity(data.city)
        setPhone(data.phone)
        setGender(data.gender)
        setModalEdit(true)
    }

    const handleClickReg = () => {
        setModalReg(true)
    }

    const handleChangeType = (value) => {
        setIdenType(value)
        setIdenNumber('')
    }

    const handleChangeDob = (value) => {
        const selectedDate = new Date(value);
        const today = new Date();
        let age = today.getFullYear() - selectedDate.getFullYear();
        const monthDiff = today.getMonth() - selectedDate.getMonth();
        const dayDiff = today.getDate() - selectedDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        if (age < 14) {
            alert('Usia Anda di bawah 14 tahun.');
        }

        setDob(value);
    }

    const clearForm = () => {
        setEmail('')
        setFirstName('')
        setLastName('')
        setIdenType('KTP')
        setIdenNumber('')
        setDob('')
        setAddress('')
        setCity('')
        setPhone('')
        setGender('Male')
    }

    const handleSubmitReg = () => {
        const param = new FormData()
        param.append('email', email)
        param.append('firstname', firstName)
        param.append('lastname', lastName)
        param.append('iden_type', idenType)
        param.append('iden_number', idenNumber)
        param.append('dob', dob)
        param.append('address', address)
        param.append('city', city)
        param.append('phone', phone)
        param.append('gender', gender)

        axios({
            method: 'POST',
            url: `${host}/loyalty/index.php?action=registerMember`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: "Success add new member",
                    position: 'top-right'
                })
                clearForm()
                setModalReg(false)
                getMemberData()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const getMemberData = async () => {
        await axios({
            method: 'GET',
            url: `${host}/loyalty/index.php?action=getMemberData`,
            headers: {
                "Content-Type": "application/json"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setMemberData(res.data.data)
            }
        })
    }

    const handleSubmitEdit = () => {
        const param = {
            'id_member': selectedRow.id_member,
            'email': email,
            'firstname': firstName,
            'lastname': lastName,
            'iden_type': idenType,
            'iden_number': idenNumber,
            'dob': dob,
            'address': address,
            'city': city,
            'phone': phone,
            'gender': gender,
        }

        axios({
            method: 'PUT',
            url: `${host}/loyalty/index.php?action=editMember`,
            headers: {
                "Content-Type": "application/json"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: "Success edit Member data",
                    position: 'top-right'
                })
                clearForm()
                setModalEdit(false)
                getMemberData()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    useEffect(() => {
        getMemberData()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Membership</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
                        <Button content="Register New Member" icon="add" color="blue" onClick={handleClickReg} />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Reacttable data={memberData} columns={columnMember} pageRow={8} noData={memberData.length === 0 ? true : false} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalReg} onClose={() => setModalReg(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Register New Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} >
                            <Form id="frmReg" onSubmit={handleSubmitReg}>
                                <Form.Group grouped>
                                    <Form.Input type="email" label="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="name@domain.com" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input type="text" width={10} label="Firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} required placeholder="Lina" />
                                    <Form.Input type="text" width={10} label="Lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Jasmine" />
                                </Form.Group>
                                <Form.Group grouped>
                                    <Form.Field>
                                        <label>Identity Number</label>
                                        <Input
                                            maxLength={idenType === 'KTP' ? 16 : 10}
                                            label={<Dropdown basic options={idenOpt} value={idenType} onChange={(e, { value }) => handleChangeType(value)} />}
                                            labelPosition='left'
                                            placeholder='Identity Number'
                                            value={idenNumber}
                                            onChange={(e) => setIdenNumber(e.target.value)}
                                            required
                                        />
                                        <Form.Input type="date" label="Date of Birth" value={dob} onChange={(e) => handleChangeDob(e.target.value)} required />
                                        <Form.Input maxLength={150} type="text" label="Address" value={address} onChange={(e) => setAddress(e.target.value)} required placeholder="Jl. Surapati No.61 Coblong" />
                                        <Form.Field>
                                            <label>City</label>
                                            <Dropdown
                                                placeholder="Select City"
                                                wrapSelection={false}
                                                options={cityOpt}
                                                selection
                                                clearable
                                                search
                                                value={city}
                                                onChange={(e, { value }) => setCity(value)}
                                            />
                                        </Form.Field>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input type="phone" label="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} required placeholder="08182989000" />
                                    <Form.Field>
                                        <label>Gender</label>
                                        <Radio
                                            label="Male"
                                            value="Male"
                                            checked={gender === "Male"}
                                            onChange={(e, { value }) => setGender(value)}
                                        /><br />
                                        <Radio
                                            label="Female"
                                            value="Female"
                                            checked={gender === "Female"}
                                            onChange={(e, { value }) => setGender(value)}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Register" icon="plus" type="submit" form="frmReg" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Member Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} >
                            <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                                <Form.Group grouped>
                                    <Form.Input type="email" label="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="name@domain.com" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input type="text" width={10} label="Firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} required placeholder="Lina" />
                                    <Form.Input type="text" width={10} label="Lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Jasmine" />
                                </Form.Group>
                                <Form.Group grouped>
                                    <Form.Field>
                                        <label>Identity Number</label>
                                        <Input
                                            maxLength={idenType === 'KTP' ? 16 : 10}
                                            label={<Dropdown basic options={idenOpt} value={idenType} onChange={(e, { value }) => handleChangeType(value)} />}
                                            labelPosition='left'
                                            placeholder='Identity Number'
                                            value={idenNumber}
                                            onChange={(e) => setIdenNumber(e.target.value)}
                                            required
                                        />
                                        <Form.Input type="date" label="Date of Birth" value={dob} onChange={(e) => handleChangeDob(e.target.value)} required />
                                        <Form.Input maxLength={150} type="text" label="Address" value={address} onChange={(e) => setAddress(e.target.value)} required placeholder="Jl. Surapati No.61 Coblong" />
                                        <Form.Field>
                                            <label>City</label>
                                            <Dropdown
                                                placeholder="Select City"
                                                wrapSelection={false}
                                                options={cityOpt}
                                                selection
                                                clearable
                                                search
                                                value={city}
                                                onChange={(e, { value }) => setCity(value)}
                                            />
                                        </Form.Field>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input type="phone" label="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} required placeholder="08182989000" />
                                    <Form.Field>
                                        <label>Gender</label>
                                        <Radio
                                            label="Male"
                                            value="Male"
                                            checked={gender === "Male"}
                                            onChange={(e, { value }) => setGender(value)}
                                        /><br />
                                        <Radio
                                            label="Female"
                                            value="Female"
                                            checked={gender === "Female"}
                                            onChange={(e, { value }) => setGender(value)}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update Data" icon="plus" form="frmEdit" color="blue" />
                </Modal.Footer>
            </Modal>
        </>
    )
}