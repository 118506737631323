import React, { useState } from "react";
import Stack from "react-bootstrap/Stack";
import { Button, Input } from "semantic-ui-react";
import { Toaster } from "./Toaster";

export const QtyInput = ({ onChange, value, max }) => {
    const handleIncrement = () => {
        onChange(parseInt(value) < max  ? parseInt(value) + 1 : max)
        if (parseInt(value) === parseInt(max) ){
            Toaster.fire({icon:'warning', text:`Stock only ${max}, cannot exceed available stock.`})
        }
    }

    const handleDecrement = () => {
        if (value > 1) {
            onChange(parseInt(value) - 1)
        }
    }

    return (
        <div className="quantity-input">
            <Stack direction="horizontal">
                <Button onClick={handleDecrement} color="teal" style={{borderRadius:'2px', padding: '4px 3px 0px 3px', marginRight:'2px'}} icon={{name:'minus', size:'small'}} />
                <input
                    type="text"
                    size="mini"
                    value={value}
                    onChange={(e) => onChange(parseInt(e.target.value))}
                    readOnly
                    className="text-center fw-bold"
                    style={{
                        width:'2em',
                        fontSize:'1em',
                        border:'none',
                    }}
                />
                <Button onClick={handleIncrement} color="teal" style={{borderRadius:'2px', padding: '4px 3px 0px 3px', marginLeft:'1px'}} icon={{name:'plus', size:'small'}} />
            </Stack>
        </div>
    )
}

