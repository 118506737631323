import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Reacttable } from "../component/Reacttable";
import { Button, ButtonGroup, Dropdown, Icon, Input, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import { Toaster } from "../component/Toaster";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment";
import Tabs from 'rsuite/Tabs';
import Badge from "rsuite/Badge";
import Table from "react-bootstrap/Table"
import { curency, curencyNoSym } from "../component/utils";
import { BsBagCheck, BsBagX } from "react-icons/bs";

export const PosTransactionData = () => {
    const openPos = useLocation().state
    const { host, userProfile } = useMyGlobal()
    const [posTrx, setPosTrx] = useState([])
    const [posReturn, setPosReturn] = useState([])
    const [modalDetail, setModalDetail] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [totalCash, setTotalCash] = useState(0)
    const [totalCard, setTotalCard] = useState(0)
    const [modalPayDet, setModalPayDet] = useState(false)
    const [selectExport, setSelectExport] = useState(null)
    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('day')),
        new Date(moment()),
    ])

    const columnsPosTrx = useMemo(() => [
        {
            Header: "Trx Code",
            accessor: "header.trx_code"
        },
        {
            Header: "Open ID",
            accessor: "header.id_open"
        },
        {
            Header: "Date",
            accessor: "header.trx_date"
        },
        {
            Header: "Time",
            accessor: "header.trx_time"
        },
        {
            Header: "Sales",
            accessor: "header.sales_name"
        },
        {
            Header: "Kasir",
            accessor: "header.user_name"
        },
        {
            Header: "Qty",
            accessor: "header.total_qty"
        },
        {
            Header: "Total Trx",
            accessor: "header.total_trx",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Voucher",
            accessor: "payment.total_voucher",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Payment",
            accessor: "payment.total_payment",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Type",
            accessor: "payment.payment_type",
            Cell: ({ cell }) => (
                <div>
                    <span className="me-2"><Badge color={cell.value === 'cash' ? 'green' : cell.value === 'card' ? 'blue' : 'yellow'} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup size="tiny" content="View Detail" trigger={
                        <Button icon="list layout" onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Payment Detail" trigger={
                        <Button icon="money outline" onClick={() => handleClickPayDetail(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Reprint" trigger={
                        <Button icon="print" onClick={() => handleClickReprint(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article",
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '140px' }}>
                    <div className="text-truncate">{cell.value}</div>
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Qty",
            accessor: "qty_order"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Disc",
            accessor: "additional_discount",
            Cell: ({ cell }) => cell.value !== "" ? `${cell.value}%` : ""
        },
        {
            Header: "Add Disc",
            accessor: "additional_discount_value",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Final Price",
            accessor: "final_price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Return",
            accessor: "is_return",
            Cell: ({ cell }) => (
                cell.value === "1" ?
                    <><span className="me-2"><Badge color="red" /></span><span>Returned</span></>
                    :
                    <></>
            )
        },
    ])

    const columnsRetur = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_return",
        },
        {
            Header: "Code Trx",
            accessor: "code_trx",
        },
        {
            Header: "Date",
            accessor: "date_return",
        },
        {
            Header: "Article",
            accessor: "article",
        },
        {
            Header: "Size",
            accessor: "size",
        },
        {
            Header: "Barcode",
            accessor: "barcode",
        },
        {
            Header: "AdDsc",
            accessor: "add_dsc",
        },
        {
            Header: "Reason",
            accessor: "description",
        },
    ])

    const handleClickPayDetail = (data) => {
        setSelectedRow(data)
        setModalPayDet(true)
    }

    const handleClickDetail = (data) => {
        setSelectedRow(data)
        setModalDetail(true)
    }

    const getPosTrx = async (idShowroom) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'posTransactionByShowroom',
                date_range: date_range,
                id_showroom: idShowroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                const cash = res.data.data.reduce((total, trx) => total + parseFloat(trx.payment.pay_cash), 0)
                const card = res.data.data.reduce((total, trx) => total + parseFloat(trx.payment.pay_card), 0)
                setPosTrx(res.data.data)
                setTotalCash(cash)
                setTotalCard(card)
            }
        })
    }

    const getReturnData = async (idShowroom) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/pos/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'getReturnData',
                date_range: date_range,
                id_showroom: idShowroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setPosReturn(res.data.data)
            }
        })
    }

    const handlePrint = (data) => {
        const locHost = "http://localhost"
        axios({
            method: "POST",
            url: `${locHost}/pos_print/`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data)
        }).then(res => {
            if (res.data.status === 'success') {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success transaction is being printed',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.message,
                    position: 'top-right'
                })
            }
        })
    }

    const handleClickReprint = (data) => {
        setSelectedRow(data)
        const cfm = window.confirm("Are you sure?")
        if (cfm) {
            let dataTrx = null
            const header = {
                trx_code: data.header.trx_code,
                id_open: data.header.id_open,
                kasir_id: data.header.id_kasir,
                kasir: data.header.user_name,
                trx_time: data.header.trx_time,
                trx_date: data.header.trx_date,
                sales: data.header.sales_name,
                sales_id: data.header.id_sales,
                store_name: data.header.showroom_name,
                store_addr: openPos.address,
                store_id: data.header.id_showroom,
                id_warehouse: openPos.id_warehouse,
                total_trx: data.header.total_trx,
                total_qty: data.detail.reduce((total, item) => total + parseInt(item.qty), 0),
            }
            const detail = data.detail
            const dataPayment = {
                grand_total: data.payment.grand_total,
                total_payment: data.payment.total_payment,
                paid: data.payment.paid,
                change: data.payment.change,
                pay_type: data.payment.payment_type,
                total_voucher: data.payment.total_voucher,
                card: data.payment.card_type,
                edc: data.payment.edc_desc,
                last_digit: data.payment.last_digit,
                ref: data.payment.ref_number,
                bank: data.payment.bank,
                desc: data.payment.desc,
            }
            dataTrx = {
                transaction: {
                    header: header,
                    detail: detail,
                },
                payment: dataPayment,
                action: "Reprint",
            }

            if (dataTrx) {
                // handlePrint(dataTrx)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: 'Data is incomplete',
                    position: 'top-right'
                })
            }
        }
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const exportToExcel = (type, showroom, fileName) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        const param = {
            showroom: showroom,
            date_range: date_range,
            type: type,
        }

        axios({
            method: "POST",
            url: `${host}/pos/index.php?action=exportSalesReport`,
            headers: {
                "Content-Type": "application/json",
            },
            data: param,
            responseType: 'blob'
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nama file yang akan diunduh
            document.body.appendChild(link);
            link.click();
            // Bersihkan URL Blob setelah file diunduh
            window.URL.revokeObjectURL(url);
        })
    }

    const handleExport = () => {
        exportToExcel(selectExport, openPos.id_showroom, selectExport === 'transaction' ? 'export_transaction.xlsx' : 'export_return.xlsx')
    }

    useEffect(() => {
        getPosTrx(openPos.id_showroom, dateRange)
        getReturnData(openPos.id_showroom, dateRange)
    }, [dateRange])

    useEffect(() => {

    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Transaction Data</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} xs={12}>
                        <Row>
                            <Col xl={6} lg={5} md={4} xs={12} className="mb-3">
                                <DateRangePicker
                                    label="Date Range : "
                                    format="yyyy-MM-dd"
                                    placement="bottomStart"
                                    cleanable={true}
                                    defaultValue={dateRange}
                                    value={dateRange}
                                    onChange={handleChangeDate}
                                    onClean={() => setDateRange([
                                        new Date(moment().startOf('month')),
                                        new Date(moment()),
                                    ])}
                                />
                            </Col>
                            <Col Col xl={2} lg={2} md={2} xs={12} className="mb-3">
                                <Input readOnly value={curencyNoSym.format(totalCash)} fluid icon="money outline" iconPosition="left" />
                            </Col>
                            <Col Col xl={2} lg={2} md={2} xs={12} className="mb-3">
                                <Input readOnly value={curencyNoSym.format(totalCard)} fluid icon="payment" iconPosition="left" />
                            </Col>
                            <Col xl={2} lg={3} md={2} xs={12} className="mb-3">
                                <ButtonGroup color='blue'>
                                    <Button onClick={handleExport}>Export to Excel</Button>
                                    <Dropdown
                                        className='button icon'
                                        floating
                                        options={[{ key: 0, text: 'Transaction', value: 'transaction', icon: { name: 'cart', color: 'black' } }, { key: 1, text: 'Return', value: 'return', icon: { name: 'undo', color: 'black' } }]}
                                        trigger={<></>}
                                        onChange={(e, { value }) => setSelectExport(value)}
                                    />
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={10} lg={12} md={12} xs={12}>
                        <Tabs defaultActiveKey="1" appearance="subtle">
                            <Tabs.Tab eventKey="1" title="POS Transaction" icon={<BsBagCheck size={18} />}>
                                <Reacttable data={posTrx} columns={columnsPosTrx} pageRow={8} totals={['header.total_trx', 'payment.total_payment', 'payment.total_voucher', 'header.total_qty']} noData={posTrx.length === 0 ? true : false}/>
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="2" title="POS Transaction Return" icon={<BsBagX size={18} />}>
                                <Reacttable data={posReturn} columns={columnsRetur} pageRow={10} noData={posReturn.length === 0 ? true : false}/>
                            </Tabs.Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Detail Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={selectedRow?.detail} columns={columnsDetail} pageRow={10} />
                </Modal.Body>
            </Modal>

            <Modal open={modalPayDet} onClose={() => setModalPayDet(false)} overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Payment Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <div style={{ border: 'solid 1px lightgrey', borderRadius: '5px', padding: '10px' }}>
                                <Table style={{ fontSize: '0.9em' }} className="table">
                                    <tbody >
                                        <tr>
                                            <td>Type</td>
                                            <td className="text-end">:</td>
                                            <td className="text-capitalize text-end fw-bold">
                                                <span className="me-2"><Badge color={selectedRow?.payment?.payment_type === 'cash' ? 'green' : selectedRow?.payment?.payment_type === 'card' ? 'blue' : 'yellow'} /></span>
                                                <span>{selectedRow?.payment?.payment_type}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Total</td>
                                            <td className="text-end">:</td>
                                            <td className="text-capitalize text-end fw-bold">{curency.format(selectedRow?.payment?.grand_total)}</td>
                                        </tr>
                                        <tr>
                                            <td>Voucher</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.total_voucher)}</td>
                                        </tr>
                                        <tr>
                                            <td>Return Deposit</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.deposit ? curency.format(selectedRow?.payment?.deposit) : 'Rp 0'}</td>
                                        </tr>
                                        <tr>
                                            <td>Card Number</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.last_digit}</td>
                                        </tr>
                                        <tr>
                                            <td>Trace Number</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.ref_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Paid</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.total_paid ? curency.format(selectedRow?.payment?.total_paid) : 'Rp 0'}</td>
                                        </tr>
                                        <tr>
                                            <td>Change</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.change)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cash</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.pay_cash)}</td>
                                        </tr>
                                        <tr>
                                            <td>Card</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.pay_card)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}