import React, { useEffect, useMemo, useState } from "react";
import { useMyGlobal } from "../component/Global";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import moment from "moment";
import { Button, Icon, Popup } from "semantic-ui-react";
import DateRangePicker from 'rsuite/DateRangePicker';
import { Reacttable } from "../component/Reacttable";
import Stack from "react-bootstrap/Stack";
import Modal from "rsuite/Modal"
import Timeline from 'rsuite/Timeline';

export const StockMovement = () => {
    const [dataMovement, setDataMovement] = useState([])
    const { host, userProfile } = useMyGlobal()
    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columns = useMemo(() => [
        {
            Header: 'Event',
            accessor: 'event'
        },
        {
            Header: 'Article',
            accessor: 'article'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Qty',
            accessor: 'qty',
        },
        {
            Header:'Cond',
            accessor:null,
            Cell:({cell}) => {
                if (cell.row.original.move_type === 'IN') {
                    return(
                        <Popup content="In" size="tiny" trigger={<span><Icon name="arrow alternate circle down outline" size="large" color="blue"/></span> }/>
                    )
                }else{
                    return (
                        <Popup content="Out" size="tiny" trigger={<span><Icon name="arrow alternate circle up outline" size="large" color="pink"/></span> }/>
                    )
                }
            }
        },
        {
            Header: 'Before',
            accessor: 'qty_before'
        },
        {
            Header: 'Current',
            accessor: "qty_current",
        },
        {
            Header: 'Warehouse',
            accessor: 'warehouse_name'
        },
        {
            Header: 'Time',
            accessor: 'move_time'
        },
        {
            Header: 'User',
            accessor: 'user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" gap={0} style={{lineHeight:'1em'}}>
                    <div>{cell.value}</div>
                    <div className="text-muted">{cell.row.original.role_name}</div>
                </Stack>
            )
        },
    ])


    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const getStockMovement = (dateRange, art) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        const article = art

        axios({
            method: "GET",
            url: `${host}/stock/index.php?action=stockMovement`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                date_range,
                article,
            },
        }).then(res => {
            if (res.data.status === 200) {
                setDataMovement(res.data.data)
            }
        })
    }

    useEffect(() => {
        getStockMovement(dateRange, null)
    }, [dateRange])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Stock Movement</div>
                </div>
                <Row className="d-flex justify-content-start">
                    <Col lg={12} className="mb-3">
                        <DateRangePicker
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="bottomStart"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                    </Col>
                    <Col lg={12}>
                        <Reacttable data={dataMovement} columns={columns} pageRow={10} noData={dataMovement.length === 0 ? true : false}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}